import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setChecklistLine = createAction(
  "[ChecklistLine View Page] Set ChecklistLine",
  props<{ checklistLineId: ApiId }>()
);

export const unsetChecklistLine = createAction(
  "[ChecklistLine View Page] Unset ChecklistLine"
);

export const updateChecklistLine = createAction(
  "[ChecklistLine View Page] Open ChecklistLine Edit Page"
);
