import { ResUser } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchUsers = createAction("[User API] Search Users");

export const searchUsersSuccess = createAction(
  "[User API] Users Search Success",
  props<{ users: ResUser[] }>()
);

export const searchUsersFailure = createAction(
  "[User API] Users Search Failure",
  props<{ error: string }>()
);
