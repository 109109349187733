import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setAttachment = createAction(
  "[Attachments View Page] Set Attachment",
  props<{ attachmentId: ApiId }>()
);

export const unsetAttachment = createAction(
  "[Attachments View Page] Unset Attachment"
);

export const downloadAttachment = createAction(
  "[Attachments View Page] Download Attachment"
);

export const openAttachment = createAction(
  "[Attachments View Page] Open Attachment"
);

export const updateAttachment = createAction(
  "[Attachments View Page] Open Attachment Put Page"
);

export const deleteAttachment = createAction(
  "[Attachments View Page] Delete Attachment"
);
