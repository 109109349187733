import {
  ApiId,
  ChecklistLine,
  ChecklistLineFormValue,
  ChecklistTemplate,
  IChecklistForm,
} from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchChecklistLines = createAction(
  "[ChecklistLine API] Search ChecklistLines",
  props<{ filter?: any[] }>()
);

export const searchChecklistLinesSuccess = createAction(
  "[ChecklistLine API] ChecklistLines Search Success",
  props<{ checklistLines: ChecklistLine[] }>()
);

export const searchChecklistLinesFailure = createAction(
  "[ChecklistLine API] ChecklistLines Search Failure",
  props<{ error: string }>()
);

export const setChecklist = createAction(
  "[ChecklistLine API] Set Checklist",
  props<{ checklistId: ApiId }>()
);

export const setOperation = createAction(
  "[ChecklistLine API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const setChecklistLineForm = createAction(
  "[ChecklistLine API] Set ChecklistLine Form",
  props<{ checklistLineFormValue: ChecklistLineFormValue }>()
);

export const setChecklistLineTemplate = createAction(
  "[ChecklistLine API] Set ChecklistLine Template",
  props<{ checklistLineFormValue: ChecklistLineFormValue }>()
);

export const createChecklistLineSuccess = createAction(
  "[ChecklistLine API] ChecklistLine Create Success",
  props<{ checklistLine: ChecklistLine }>()
);

export const createChecklistLineFailure = createAction(
  "[ChecklistLine API] ChecklistLine Create Failure",
  props<{ error: string }>()
);

export const updateChecklistLineSuccess = createAction(
  "[ChecklistLine API] ChecklistLine Update Success",
  props<{ checklistLine: ChecklistLine }>()
);

export const updateChecklistLineFailure = createAction(
  "[ChecklistLine API] ChecklistLine Update Failure",
  props<{ error: string }>()
);

export const syncChecklistLineSuccess = createAction(
  "[ChecklistLine API] Synchronize ChecklistLine Success",
  props<{ checklistLine: ChecklistLine }>()
);

export const checkListLineFormInvalid = createAction(
  "[ChecklistLine API] ChecklistLine Form Invalid"
);
