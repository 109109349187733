import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { adapter, selectForm, selectId } from "./companies.reducer";

export const selectCompanies = (state: AppState) => state.company;

export const {
  selectIds: selectCompanyIds,
  selectEntities: selectCompanyEntities,
  selectAll: selectAllCompanies,
  selectTotal: selectCompaniesTotal,
} = adapter.getSelectors(selectCompanies);

export const selectCompanyId = createSelector(selectCompanies, selectId);

export const selectCompany = createSelector(
  selectCompanyEntities,
  selectCompanyId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectCompanyName = createSelector(
  selectCompany,
  (company) => company && company.name
);

export const selectCompanyForm = createSelector(selectCompanies, selectForm);
