import { ResPartner } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchPartners = createAction("[Partner API] Search Partners");

export const searchPartnersSuccess = createAction(
  "[Partner API] Partners Search Success",
  props<{ partners: ResPartner[] }>()
);

export const searchPartnersFailure = createAction(
  "[Partner API] Partners Search Failure",
  props<{ error: string }>()
);
