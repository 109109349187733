import { ToolboxTalkTemplate } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ToolboxTalkPutPageActions } from "../actions";
import { StateStatus } from "../model";
import { ToolboxTalkTemplateApiActions } from "./actions";

export interface ToolboxTalkTemplateState
  extends EntityState<ToolboxTalkTemplate> {
  selectedId: string | null;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<ToolboxTalkTemplate> =
  createEntityAdapter<ToolboxTalkTemplate>({
    selectId: (toolboxTalkTemplate: ToolboxTalkTemplate) =>
      toolboxTalkTemplate.id,
    sortComparer: false,
  });

export const initialState: ToolboxTalkTemplateState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const toolboxTalkTemplateReducer = createReducer(
  initialState,

  on(ToolboxTalkTemplateApiActions.searchToolboxTalkTemplates, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    ToolboxTalkTemplateApiActions.searchToolboxTalkTemplatesSuccess,
    (state, { toolboxTalkTemplates }) =>
      adapter.setAll(toolboxTalkTemplates, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    ToolboxTalkTemplateApiActions.searchToolboxTalkTemplatesFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(ToolboxTalkPutPageActions.setTemplate, (state, { id }) => ({
    ...state,
    selectedId: id,
  }))
);

export const selectId = (state: ToolboxTalkTemplateState) => state.selectedId;

export const selectFilter = (state: ToolboxTalkTemplateState) => state.filter;
