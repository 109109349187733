import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchAttachments = createAction(
  "[Attachments List Page] Search Attachments"
);

export const createAttachment = createAction(
  "[Attachments List Page] Create Attachment"
);

export const setOperation = createAction(
  "[Attachments List Page] Set Operation",
  props<{ operationId: ApiId }>()
);
