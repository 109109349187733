import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setRiskAssessment = createAction(
  "[RiskAssessment View Page] Set RiskAssessment",
  props<{ assessmentId: ApiId }>()
);

export const unsetRiskAssessment = createAction(
  "[RiskAssessment View Page] Unset RiskAssessment"
);

export const editRiskAssessment = createAction(
  "[RiskAssessment View Page] Edit RiskAssessment"
);

export const openRiskAssessmentReport = createAction(
  "[RiskAssessment View Page] Open RiskAssessment Report"
);

export const downloadRiskAssessmentReport = createAction(
  "[RiskAssessment View Page] Download RiskAssessment Report"
);
