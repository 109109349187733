import { Checklist } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { selectAllChecklistLines } from "../checklist-lines/checklist-lines.selectors";
import { selectRelatedItems } from "../model";
import {
  adapter,
  selectError,
  selectId,
  selectLoading,
  selectStatus,
} from "./checklists.reducer";

export const selectChecklists = (state: AppState) => state.checklists;

export const {
  selectIds: selectChecklistIds,
  selectEntities: selectChecklistEntities,
  selectAll: selectAllChecklists,
  selectTotal: selectChecklistsTotal,
} = adapter.getSelectors(selectChecklists);

export const selectChecklistsStatus = createSelector(
  selectChecklists,
  selectStatus
);

export const selectChecklistError = createSelector(
  selectChecklists,
  selectError
);

export const selectChecklistLoading = createSelector(
  selectChecklists,
  selectLoading
);

export const selectChecklistId = createSelector(selectChecklists, selectId);

export const selectChecklist = createSelector(
  selectChecklistEntities,
  selectChecklistId,
  (entities, selectedId) =>
    (!!selectedId && entities[selectedId]) || new Checklist()
);

export const selectChecklistChecklistLines = createSelector(
  selectAllChecklistLines,
  selectChecklist,
  (checklistLines, checklist) =>
    checklistLines.filter((checklistLine) =>
      selectRelatedItems(checklist, checklistLine.checklist_id)
    )
);
