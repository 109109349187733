import { DiveJob } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchDiveJobs = createAction("[DiveJob API] Search DiveJobs");

export const searchDiveJobsSuccess = createAction(
  "[DiveJob API] DiveJobs Search Success",
  props<{ diveJobs: DiveJob[] }>()
);

export const searchDiveJobsFailure = createAction(
  "[DiveJob API] DiveJobs Search Failure",
  props<{ error: string }>()
);
