import {
  ApiId,
  DiveLogLine,
  DiveLogLineFormValue,
  Timesheet,
} from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setDiveLog = createAction(
  "[DiveLogLine API] Set DiveLog",
  props<{ logId: ApiId }>()
);

export const setOperation = createAction(
  "[DiveLogLine API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const searchDiveLogLines = createAction(
  "[DiveLogLine API] Search DiveLogLine"
);

export const searchDiveLogLinesSuccess = createAction(
  "[DiveLogLine API] Search DiveLogLines Success",
  props<{ diveLogLines: DiveLogLine[] }>()
);

export const searchDiveLogLinesFailure = createAction(
  "[DiveLogLine API] Search DiveLogLine Failure",
  props<{ error: string }>()
);

export const createDiveLogLineSuccess = createAction(
  "[DiveLogLine API] DiveLogLines Create Success",
  props<{ diveLogLine: DiveLogLine }>()
);

export const createDiveLogLineFailure = createAction(
  "[DiveLogLine API] DiveLogLine Create Failure",
  props<{ error: string }>()
);

export const updateDiveLogLineSuccess = createAction(
  "[DiveLogLine API] DiveLogLine Update Success",
  props<{ diveLogLine: DiveLogLine }>()
);

export const updateDiveLogLineFailure = createAction(
  "[DiveLogLine API] DiveLogLine Update Failure",
  props<{ error: string }>()
);

export const syncDiveLogLineSuccess = createAction(
  "[DiveLogLine API] DiveLogLine Synchronize Success",
  props<{ diveLogLine: DiveLogLine }>()
);

export const setDiveLogLineForm = createAction(
  "[DiveLogLine API] Set DiveLogLine Form",
  props<{
    diveLogLineFormValue: DiveLogLineFormValue;
  }>()
);

export const setDiveLogLineTemplate = createAction(
  "[DiveLogLine API] Set DiveLogLine Template",
  props<{ diveLogLineFormValue: DiveLogLineFormValue }>()
);

export const diveLogLineFormInvalid = createAction(
  "[DiveLogLine API] DiveLogLine Form Invalid"
);

export const addTimesheetControl = createAction(
  "[DiveLogLine API] Add Timesheet Control",
  props<{ timesheet: Timesheet }>()
);
