import { IMany2One } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectFilter,
  selectId,
  selectStatus,
} from "./dive-log-templates.reducer";

export const selectDiveLogTemplates = (state: AppState) =>
  state.diveLogTemplates;

export const {
  selectIds: selectDiveLogTemplateIds,
  selectEntities: selectDiveLogTemplateEntities,
  selectAll: selectAllDiveLogTemplates,
  selectTotal: selectDiveLogTemplatesTotal,
} = adapter.getSelectors(selectDiveLogTemplates);

export const selectDiveLogTemplatesStatus = createSelector(
  selectDiveLogTemplates,
  selectStatus
);

export const selectDiveLogTemplateId = createSelector(
  selectDiveLogTemplates,
  selectId
);

export const selectDiveLogTemplate = createSelector(
  selectDiveLogTemplateEntities,
  selectDiveLogTemplateId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectDiveLogTemplateFilter = createSelector(
  selectDiveLogTemplates,
  selectFilter
);

export const selectSelectableDiveLogTemplates = createSelector(
  selectAllDiveLogTemplates,
  selectDiveLogTemplateFilter,
  (templates, filter) =>
    templates
      .filter((template) => (filter ? template.name.includes(filter) : true))
      .map((template) => <IMany2One>{ id: template.id, name: template.name })
);

export const selectDefaultDiveLogTemplate = createSelector(
  selectAllDiveLogTemplates,
  (templates) => templates.length && templates[0]
);
