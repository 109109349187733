import { ApiId, DiveLog } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[DiveLog API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const searchDiveLogs = createAction("[DiveLog API] Search DiveLogs");

export const searchDiveLogsSuccess = createAction(
  "[DiveLog API] Search DiveLogs Success",
  props<{ diveLogs: DiveLog[] }>()
);

export const searchDiveLogsFailure = createAction(
  "[DiveLog API] Search DiveLogs Failure",
  props<{ error: string }>()
);

export const createDiveLogSuccess = createAction(
  "[DiveLog API] DiveLog Create Success",
  props<{ diveLog: DiveLog }>()
);

export const createDiveLogFailure = createAction(
  "[DiveLog API] DiveLog Create Failure",
  props<{ error: string }>()
);

export const syncDiveLogSuccess = createAction(
  "[DiveLog API] DiveLog Synchronize Success",
  props<{ diveLog: DiveLog }>()
);

export const viewDiveLog = createAction(
  "[DiveLog API] Open DiveLog View Page",
  props<{ diveLog: DiveLog }>()
);

export const listDiveLogs = createAction(
  "[DiveLog API] List DiveLogs",
  props<{ operationId: ApiId }>()
);
