import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setChecklist = createAction(
  "[Checklist View Page] Set Checklist",
  props<{ checklistId: ApiId }>()
);

export const unsetChecklist = createAction(
  "[Checklist View Page] Unset Checklist"
);

export const viewChecklistLine = createAction(
  "[Checklist View Page] View Checklist Line",
  props<{ checklistLineId: ApiId }>()
);

export const createChecklistLine = createAction(
  "[Checklist View Page] Create Checklist Line"
);

export const updateChecklistLine = createAction(
  "[Checklist View Page] Open Checklist Edit Page"
);
