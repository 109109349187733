import { DiveSite } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchDiveSites = createAction("[DiveSite API] Search DiveSites");

export const searchDiveSitesSuccess = createAction(
  "[DiveSite API] DiveSites Search Success",
  props<{ diveSites: DiveSite[] }>()
);

export const searchDiveSitesFailure = createAction(
  "[DiveSite API] DiveSites Search Failure",
  props<{ error: string }>()
);
