import {
  DIVE_OPERATION_MODEL,
  DiveOperation,
  IDiveOperationForm,
  ITimesheetForm,
  Timesheet,
} from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  addArrayControl,
  box,
  createFormGroupState,
  FormGroupState,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  onNgrxFormsAction,
  removeArrayControl,
  reset,
  setValue,
  SetValueAction,
  unbox,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import {
  AttachmentApiActions,
  AttachmentListPageActions,
  ChecklistApiActions,
  ChecklistLineApiActions,
  DiveLogApiActions,
  DiveLogLineApiActions,
  RiskAssessmentApiActions,
  ToolboxTalkApiActions,
} from "../actions";
import { getSelectId, StateStatus } from "../model";
import {
  OperationApiActions,
  OperationListPageActions,
  OperationPutPageActions,
  OperationTimesheetsListPageActions,
  OperationTimesheetsPutPageActions,
  OperationViewPageActions,
} from "./actions";

export interface OperationState extends EntityState<DiveOperation> {
  selectedId: string;
  error: string;
  filter: string;
  status: StateStatus;
  form: FormGroupState<IDiveOperationForm>;
}

export const initialOperationFormValue: IDiveOperationForm = {
  job_id: box(null),
  partner_id: box(null),
  site_id: box(null),
  supervisor_id: box(null),
  diver_ids: box([]),
  date_start: null,
  time_sheet_ids: [],
  time_sheet_comment: null,
};

const validateForm = updateGroup<IDiveOperationForm>({
  date_start: validate(required),
  job_id: validate(required),
  partner_id: validate(required),
  site_id: validate(required),
  time_sheet_ids: updateArray(
    updateGroup<ITimesheetForm>({
      name: validate(required),
      time: validate(required),
    })
  ),
});

export const adapter: EntityAdapter<DiveOperation> =
  createEntityAdapter<DiveOperation>({
    selectId: (operation: DiveOperation) => operation._id,
    sortComparer: (a: DiveOperation, b: DiveOperation): number => {
      const dateComparison = b.date.getTime() - a.date.getTime();
      if (dateComparison !== 0) return dateComparison;
      const nameComparison = b.name.localeCompare(a.name);
      return nameComparison;
    },
  });

export const operationInitialState: OperationState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
  form: createFormGroupState<IDiveOperationForm>(
    DIVE_OPERATION_MODEL,
    initialOperationFormValue
  ),
});

const rawReducer = createReducer(
  operationInitialState,

  onNgrxForms(),

  on(OperationListPageActions.searchOperations, (state) => ({
    ...state,
    status: StateStatus.Loading,
  })),

  on(OperationApiActions.searchOperationsSuccess, (state, { operations }) =>
    adapter.setAll(operations, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(
    OperationViewPageActions.setOperation,
    OperationPutPageActions.setOperation,
    OperationApiActions.setOperation,
    RiskAssessmentApiActions.setOperation,
    ChecklistApiActions.setOperation,
    ChecklistLineApiActions.setOperation,
    ToolboxTalkApiActions.setOperation,
    DiveLogApiActions.setOperation,
    DiveLogLineApiActions.setOperation,
    OperationTimesheetsListPageActions.setOperation,
    OperationTimesheetsPutPageActions.setOperation,
    AttachmentListPageActions.setOperation,
    AttachmentApiActions.setOperation,
    (state, { operationId }) => ({
      ...state,
      selectedId: getSelectId(state, operationId),
    })
  ),

  on(OperationViewPageActions.unsetOperation, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(OperationListPageActions.setFilter, (state, { filter }) => ({
    ...state,
    filter: filter,
  })),

  on(
    OperationPutPageActions.createOperation,
    OperationPutPageActions.updateOperation,
    OperationListPageActions.archiveOperation,
    OperationTimesheetsPutPageActions.updateTimesheets,
    (state) => ({
      ...state,
      status: StateStatus.Loading,
      error: null,
    })
  ),

  on(OperationApiActions.deleteOperationSuccess, (state, { id }) =>
    adapter.removeOne(id, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(
    OperationApiActions.createOperationSuccess,
    OperationApiActions.updateOperationSuccess,
    OperationApiActions.updateOperationTimesheetsSuccess,
    (state, { operation }) =>
      adapter.upsertOne(operation, {
        ...state,
        status: StateStatus.Success,
        error: null,
        form: markAsPristine(state.form),
      })
  ),

  on(OperationApiActions.synchronizeOperationSuccess, (state, { operation }) =>
    adapter.upsertOne(operation, state)
  ),

  on(
    OperationApiActions.searchOperationsFailure,
    OperationApiActions.createOperationFailure,
    OperationApiActions.updateOperationFailure,
    OperationApiActions.deleteOperationFailure,
    OperationApiActions.updateOperationTimesheetsFailure,
    ChecklistApiActions.createChecklistFailure,
    ToolboxTalkApiActions.createToolboxTalkFailure,
    DiveLogApiActions.createDiveLogFailure,
    RiskAssessmentApiActions.createRiskAssessmentFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(
    OperationApiActions.setOperationForm,
    (state, { operationFormValue: operationForm }) => ({
      ...state,
      form: setValue(state.form, { ...state.form.value, ...operationForm }),
    })
  ),

  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (
      action.controlId === state.form.controls.partner_id.id &&
      unbox(state.form.value.site_id) !== null
    ) {
      return {
        ...state,
        form: updateGroup(state.form, {
          site_id: setValue(box(null)),
        }),
      };
    } else {
      return state;
    }
  }),

  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (
      action.controlId === state.form.controls.partner_id.id &&
      unbox(state.form.value.site_id) !== null
    ) {
      return {
        ...state,
        form: updateGroup(state.form, {
          site_id: setValue(box(null)),
        }),
      };
    } else {
      return state;
    }
  }),

  on(OperationTimesheetsPutPageActions.addTimesheet, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      time_sheet_ids: addArrayControl({ ...new Timesheet() }),
    }),
  })),

  on(OperationTimesheetsPutPageActions.remTimesheet, (state, { id }) => ({
    ...state,
    form: updateGroup(state.form, {
      time_sheet_ids: (time_sheet_ids) => {
        const control = time_sheet_ids.controls.find(
          (control) => control.id === id
        );
        const index = time_sheet_ids.controls.indexOf(control);
        return removeArrayControl(time_sheet_ids, index);
      },
    }),
  })),

  on(OperationApiActions.operationFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(
    OperationPutPageActions.resetOperationForm,
    OperationTimesheetsPutPageActions.resetOperationForm,
    (state) => ({
      ...state,
      form: setValue(reset(state.form), initialOperationFormValue),
    })
  ),

  on(
    OperationApiActions.synchronizeOperationGroupPending,
    (state, { operation }) =>
      adapter.upsertOne({ ...operation, sync_status: "pending" }, state)
  ),

  on(
    OperationApiActions.synchronizeOperationGroupStarted,
    (state, { operation }) =>
      adapter.upsertOne({ ...operation, sync_status: "syncing" }, state)
  ),

  on(
    OperationApiActions.synchronizeOperationGroupSuccess,
    (state, { operation }) =>
      adapter.upsertOne({ ...operation, sync_status: "success" }, state)
  ),

  on(
    OperationApiActions.synchronizeOperationGroupFailure,
    (state, { operation }) =>
      adapter.upsertOne({ ...operation, sync_status: "error" }, state)
  )
);

export const operationReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateForm
);

export const selectId = (state: OperationState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectForm = (state: OperationState) => state.form;

export const selectError = (state: OperationState) => state.error;

export const selectPending = (state: OperationState) =>
  state.status == StateStatus.Pending || state.status == StateStatus.Error;

export const selectLoading = (state: OperationState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: OperationState) =>
  state.status == StateStatus.Success;

export const selectCanActivate = (state: OperationState) =>
  ![StateStatus.Pending, StateStatus.Loading].includes(state.status);

export const selectFilter = (state: OperationState) =>
  state.filter && state.filter.toLowerCase();
