import { Attachment } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectCanActivate,
  selectError,
  selectForm,
  selectId,
  selectLoaded,
  selectLoading,
} from "./attachments.reducer";

export const selectAttachments = (state: AppState) => state.attachments;

export const {
  selectIds: selectAttachmentIds,
  selectEntities: selectAttachmentEntities,
  selectAll: selectAllAttachments,
  selectTotal: selectTotalAttachments,
} = adapter.getSelectors(selectAttachments);

export const selectAttachmentError = createSelector(
  selectAttachments,
  selectError
);

export const selectAttachmentLoading = createSelector(
  selectAttachments,
  selectLoading
);

export const selectAttachmentsLoaded = createSelector(
  selectAttachments,
  selectLoaded
);

export const selectAttachmentsCanActivate = createSelector(
  selectAttachments,
  selectCanActivate
);

export const selectAttachmentId = createSelector(selectAttachments, selectId);

export const selectAttachment = createSelector(
  selectAttachmentEntities,
  selectAttachmentId,
  (entities, selectedId) =>
    // TODO refactor other cases in this way
    (selectedId && entities[selectedId]) ?? new Attachment()
);

export const selectAttachmentForm = createSelector(
  selectAttachments,
  selectForm
);
