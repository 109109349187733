import { AppState } from "../app.state";
import { createSelector } from "@ngrx/store";
import { adapter, selectId } from "./partners.reducer";

export const selectPartners = (state: AppState) => state.partners;

export const {
  selectIds: selectPartnerIds,
  selectEntities: selectPartnerEntities,
  selectAll: selectAllPartners,
  selectTotal: selectPartnersTotal,
} = adapter.getSelectors(selectPartners);

export const selectPartnerId = createSelector(selectPartners, selectId);

export const selectPartner = createSelector(
  selectPartnerEntities,
  selectPartnerId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
