import { ChecklistTemplate } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ChecklistLinePutPageActions } from "../actions";
import { StateStatus } from "../model";
import { ChecklistTemplateApiActions } from "./actions";

export interface ChecklistTemplateState extends EntityState<ChecklistTemplate> {
  selectedId: number;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<ChecklistTemplate> =
  createEntityAdapter<ChecklistTemplate>({
    selectId: (checklistTemplate: ChecklistTemplate) => checklistTemplate.id,
    sortComparer: false,
  });

export const initialState: ChecklistTemplateState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const checklistTemplateReducer = createReducer(
  initialState,

  on(ChecklistTemplateApiActions.searchChecklistTemplates, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    ChecklistTemplateApiActions.searchChecklistTemplatesSuccess,
    (state, { checklistTemplates }) =>
      adapter.setAll(checklistTemplates, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    ChecklistTemplateApiActions.searchChecklistTemplatesFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(ChecklistLinePutPageActions.setTemplate, (state, { id }) => ({
    ...state,
    selectedId: id,
  }))
);

export const selectId = (state: ChecklistTemplateState) => state.selectedId;

export const selectFilter = (state: ChecklistTemplateState) => state.filter;

export const selectStatus = (state: ChecklistTemplateState) => state.status;
