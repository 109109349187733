import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { adapter, selectId } from "./dive-sites.reducer";

export const selectDiveSites = (state: AppState) => state.diveSites;

export const {
  selectIds: selectDiveSiteIds,
  selectEntities: selectDiveSiteEntities,
  selectAll: selectAllDiveSites,
  selectTotal: selectDiveSitesTotal,
} = adapter.getSelectors(selectDiveSites);

export const selectDiveSiteId = createSelector(selectDiveSites, selectId);

export const selectDiveSite = createSelector(
  selectDiveSiteEntities,
  selectDiveSiteId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
