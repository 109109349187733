import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { selectAllDiveLogLines } from "../dive-log-lines/dive-log-lines.selectors";
import { selectRelatedItems } from "../model";
import {
  adapter,
  selectError,
  selectId,
  selectLoading,
  selectStatus,
} from "./dive-logs.reducer";

export const selectDiveLogs = (state: AppState) => state.diveLogs;

export const {
  selectIds: selectDiveLogIds,
  selectEntities: selectDiveLogEntities,
  selectAll: selectAllDiveLogs,
  selectTotal: selectTotalDiveLogs,
} = adapter.getSelectors(selectDiveLogs);

export const selectDiveLogError = createSelector(selectDiveLogs, selectError);

export const selectDiveLogsStatus = createSelector(
  selectDiveLogs,
  selectStatus
);

export const selectDiveLogsLoading = createSelector(
  selectDiveLogs,
  selectLoading
);

export const selectDiveLogId = createSelector(selectDiveLogs, selectId);

export const selectDiveLog = createSelector(
  selectDiveLogEntities,
  selectDiveLogId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectDiveLogDiveLogLines = createSelector(
  selectAllDiveLogLines,
  selectDiveLog,
  (diveLogLines, diveLog) =>
    diveLog &&
    diveLogLines.filter((diveLogLine) =>
      selectRelatedItems(diveLog, diveLogLine.log_id)
    )
);
