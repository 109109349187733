import { ApiId, RiskAssessment, RiskAssessmentFormValue } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[RiskAssessment API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const setRiskAssessmentForm = createAction(
  "[RiskAssessment API] Set RiskAssessment Form",
  props<{ riskAssessmentFormValue: RiskAssessmentFormValue }>()
);

export const viewRiskAssessment = createAction(
  "[RiskAssessment API] View RiskAssessment",
  props<{ assessment: RiskAssessment }>()
);

export const listRiskAssessments = createAction(
  "[RiskAssessment API] List RiskAssessments",
  props<{ operationId: ApiId }>()
);

export const searchRiskAssessments = createAction(
  "[RiskAssessment API] Search RiskAssessments"
);

export const searchRiskAssessmentsSuccess = createAction(
  "[RiskAssessment API] RiskAssessments Search Success",
  props<{ assessments: RiskAssessment[] }>()
);

export const searchRiskAssessmentsFailure = createAction(
  "[RiskAssessment API] RiskAssessments Search Failure",
  props<{ error: string }>()
);

export const createRiskAssessmentSuccess = createAction(
  "[RiskAssessment API] RiskAssessment Create Success",
  props<{ assessment: RiskAssessment }>()
);

export const createRiskAssessmentFailure = createAction(
  "[RiskAssessment API] RiskAssessment Create Failure",
  props<{ error: string }>()
);

export const updateRiskAssessmentSuccess = createAction(
  "[RiskAssessment API] RiskAssessment Update Success",
  props<{ assessment: RiskAssessment }>()
);

export const updateRiskAssessmentFailure = createAction(
  "[RiskAssessment API] RiskAssessment Update Failure",
  props<{ error: string }>()
);

export const syncRiskAssessmentSuccess = createAction(
  "[RiskAssessment API] Synchronize RiskAssessment Success",
  props<{ assessment: RiskAssessment }>()
);

export const riskAssessmentFormInvalid = createAction(
  "[RiskAssessment API] RiskAssessment Form Invalid"
);

export const ensureRiskAssessmentReportSuccess = createAction(
  "[RiskAssessment API] Ensure RiskAssessment Report Success",
  props<{ assessment: RiskAssessment }>()
);

export const ensureRiskAssessmentReportFailure = createAction(
  "[RiskAssessment API] Ensure RiskAssessment Report Failure",
  props<{ assessment: RiskAssessment; error: any }>()
);

export const openRiskAssessmentReportSuccess = createAction(
  "[RiskAssessment API] Open RiskAssessment Report Success"
);

export const openRiskAssessmentReportFailure = createAction(
  "[RiskAssessment API] Open RiskAssessment Report Failure",
  props<{ error: string }>()
);

export const downloadRiskAssessmentReportSuccess = createAction(
  "[RiskAssessment API] Download RiskAssessment Report Success"
);

export const downloadRiskAssessmentReportFailure = createAction(
  "[RiskAssessment API] Download RiskAssessment Report Failure",
  props<{ error: string }>()
);
