import {
  CHECKLIST_MODEL,
  ChecklistLine,
  DiveOperationState,
  IChecklistLineForm,
} from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  box,
  createFormGroupState,
  FormGroupState,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  reset,
  setValue,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import {
  ChecklistLineApiActions,
  ChecklistLinePutPageActions,
  ChecklistLineViewPageActions,
} from "../actions";
import { getSelectId, StateStatus } from "../model";

export interface ChecklistLineState extends EntityState<ChecklistLine> {
  selectedId: string;
  error: string;
  filter: any[];
  status: StateStatus;
  form: FormGroupState<IChecklistLineForm>;
}

export const adapter: EntityAdapter<ChecklistLine> =
  createEntityAdapter<ChecklistLine>({
    selectId: (checklistLine: ChecklistLine) => checklistLine._id,
    sortComparer: false,
  });

export const initialChecklistLineFormValue: IChecklistLineForm = {
  checklist_id: null,
  name: null,
  template_id: box(null),
  signature: null,
  signed_by: null,
  item_ids: [],
};

export const initialState: ChecklistLineState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: [],
  status: StateStatus.Pending,
  form: createFormGroupState<IChecklistLineForm>(
    CHECKLIST_MODEL,
    initialChecklistLineFormValue
  ),
});

const validateFormState = updateGroup({
  name: validate(required),
});

export const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(
    ChecklistLineViewPageActions.setChecklistLine,
    ChecklistLinePutPageActions.setChecklistLine,
    (state, { checklistLineId }) => ({
      ...state,
      selectedId: getSelectId(state, checklistLineId),
    })
  ),

  on(ChecklistLineViewPageActions.unsetChecklistLine, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(
    ChecklistLineApiActions.setChecklistLineForm,
    ChecklistLineApiActions.setChecklistLineTemplate,
    (state, { checklistLineFormValue }) => ({
      ...state,
      form: setValue(state.form, {
        ...state.form.value,
        ...checklistLineFormValue,
      }),
    })
  ),

  on(ChecklistLinePutPageActions.resetSignature, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      signature: setValue(<string>null),
    }),
  })),

  on(ChecklistLineApiActions.checkListLineFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(ChecklistLinePutPageActions.resetChecklistLineForm, (state) => ({
    ...state,
    form: setValue(reset(state.form), initialChecklistLineFormValue),
  })),

  on(ChecklistLineApiActions.searchChecklistLines, (state, { filter }) => ({
    ...state,
    filter: filter,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    ChecklistLinePutPageActions.createChecklistLine,
    ChecklistLinePutPageActions.updateChecklistLine,
    (state) => ({
      ...state,
      status: StateStatus.Loading,
      error: null,
    })
  ),

  on(
    ChecklistLineApiActions.searchChecklistLinesSuccess,
    (state, { checklistLines }) =>
      adapter.setAll(checklistLines, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    ChecklistLineApiActions.createChecklistLineSuccess,
    ChecklistLineApiActions.updateChecklistLineSuccess,
    (state, { checklistLine }) =>
      adapter.upsertOne(checklistLine, {
        ...state,
        status: StateStatus.Success,
        error: null,
        form: markAsPristine(state.form),
      })
  ),

  on(
    ChecklistLineApiActions.syncChecklistLineSuccess,
    (state, { checklistLine }) => adapter.upsertOne(checklistLine, state)
  ),

  on(
    ChecklistLineApiActions.searchChecklistLinesFailure,
    ChecklistLineApiActions.createChecklistLineFailure,
    ChecklistLineApiActions.updateChecklistLineFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  )
);

export const checklistLineReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateFormState
);

export const selectId = (state: ChecklistLineState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectForm = (state: ChecklistLineState) => state.form;

export const selectError = (state: ChecklistLineState) => state.error;

export const selectLoading = (state: ChecklistLineState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: ChecklistLineState) =>
  state.status == StateStatus.Success;

export const selectCanActivate = (state: ChecklistLineState) =>
  ![StateStatus.Pending, StateStatus.Loading].includes(state.status);

export const selectState = (checklistLine: ChecklistLine) =>
  (checklistLine.signature && checklistLine.signed_by
    ? "done"
    : "draft") as DiveOperationState;
