import { createAction, props } from "@ngrx/store";

export const setToolboxTalk = createAction(
  "[ToolboxTalk Put Page] Set Toolbox Talk",
  props<{ talkId: string }>()
);

export const setTemplate = createAction(
  "[ToolboxTalk Put Page] Set Template",
  props<{ id: string }>()
);

export const updateToolboxTalk = createAction(
  "[ToolboxTalk Put Page] Update Toolbox Talk"
);

export const addTopic = createAction(
  "[ToolboxTalk Put Page] Add Toolbox Talk Topic",
  props<{ group_id: any; name: string }>()
);

export const addAttendee = createAction(
  "[ToolboxTalk Put Page] Add Toolbox Talk Attendee"
);

export const remAttendee = createAction(
  "[ToolboxTalk Put Page] Remove Toolbox Talk Attendee",
  props<{ id: string }>()
);

export const resetToolboxTalkForm = createAction(
  "[ToolboxTalk Put Page] Reset ToolboxTalk Form"
);
