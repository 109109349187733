import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setToolboxTalk = createAction(
  "[ToolboxTalk View Page] Set Toolbox Talk",
  props<{ talkId: ApiId }>()
);

export const unsetToolboxTalk = createAction(
  "[ToolboxTalk View Page] Unset Toolbox Talk"
);

export const updateToolboxTalk = createAction(
  "[ToolboxTalk View Page] Update Toolbox Talk"
);
