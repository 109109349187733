import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setDiveLog = createAction(
  "[DiveLog View Page] Set DiveLog",
  props<{ logId: ApiId }>()
);

export const unsetDiveLog = createAction("[DiveLog View Page] Unset DiveLog");

export const viewDiveLogLine = createAction(
  "[DiveLog View Page] Open DiveLogLine View Page",
  props<{ diveLogLineId: ApiId }>()
);

export const createDiveLogLine = createAction(
  "[DiveLog View Page] Open DiveLogLine Create Page"
);
