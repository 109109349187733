import { ChecklistTemplate } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchChecklistTemplates = createAction(
  "[ChecklistTemplate API] Search ChecklistTemplates"
);

export const searchChecklistTemplatesSuccess = createAction(
  "[ChecklistTemplate API] ChecklistTemplates Search Success",
  props<{ checklistTemplates: ChecklistTemplate[] }>()
);

export const searchChecklistTemplatesFailure = createAction(
  "[ChecklistTemplate API] ChecklistTemplates Search Failure",
  props<{ error: string }>()
);
