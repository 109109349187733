import { AuthUser } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const init = createAction(
  "[Auth API] Init",
  props<{ loggedIn: boolean; user: AuthUser }>()
);

export const loginSuccess = createAction(
  "[Auth API] Login Success",
  props<{ loggedIn: boolean; user: AuthUser }>()
);

export const loginFailure = createAction(
  "[Auth API] Login Failure",
  props<{ error: string }>()
);

export const authFormInvalid = createAction("[Auth API] Auth Form Invalid");

export const load = createAction("[Auth API] Load");

export const setCompanySuccess = createAction("[Auth API] Set Company Success");
