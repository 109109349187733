import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Operation Timesheets Put Page] Set Operation",
  props<{ operationId: ApiId }>()
);

export const addTimesheet = createAction(
  "[Operation Timesheets Put Page] Add Timesheet"
);

export const remTimesheet = createAction(
  "[Operation Timesheets Put Page] Remove Timesheet",
  props<{ id: string }>()
);

export const updateTimesheets = createAction(
  "[Operation Timesheets Put Page] Update Operation Timesheets"
);

export const resetOperationForm = createAction(
  "[Operation Timesheets Put Page] Reset Operation Form"
);
