import { Company, ICompanyForm } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setCompanies = createAction(
  "[Company API] Set Companies",
  props<{ companies: Company[]; selectedId: number }>()
);

export const setFormControls = createAction(
  "[Company API] Set Form Controls",
  props<{ companyForm: ICompanyForm }>()
);
