import {
  ApiId,
  IRiskAssessmentChangeForm,
  IRiskAssessmentForm,
  IRiskAssessmentSigneeForm,
  RISK_ASSESSMENT_MODEL,
  RiskAssessment,
  RiskAssessmentChange,
  RiskAssessmentSignee,
} from "@app/models";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  FormGroupState,
  addArrayControl,
  createFormGroupState,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  removeArrayControl,
  reset,
  setValue,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { StateStatus, getSelectId } from "../model";
import {
  RiskAssessmentApiActions,
  RiskAssessmentPutPageActions,
  RiskAssessmentSegmentActions,
  RiskAssessmentViewPageActions,
} from "./actions";

export enum RiskAssessmentSegment {
  Signees = "signees",
  Changes = "changes",
}

export interface RiskAssessmentState extends EntityState<RiskAssessment> {
  selectedId: ApiId;
  segment: RiskAssessmentSegment;
  error: string;
  status: StateStatus;
  form: FormGroupState<IRiskAssessmentForm>;
}

export const adapter: EntityAdapter<RiskAssessment> =
  createEntityAdapter<RiskAssessment>({
    selectId: (talk: RiskAssessment) => talk._id,
    sortComparer: false,
  });

export const initialRiskAssessmentFormValue: IRiskAssessmentForm = {
  signee_ids: [],
  change_ids: [],
};

export const initialState: RiskAssessmentState = adapter.getInitialState({
  selectedId: null,
  segment: RiskAssessmentSegment.Signees,
  error: null,
  status: StateStatus.Pending,
  form: createFormGroupState<IRiskAssessmentForm>(
    RISK_ASSESSMENT_MODEL,
    initialRiskAssessmentFormValue
  ),
});

const validateForm = updateGroup<IRiskAssessmentForm>({
  signee_ids: updateArray(
    updateGroup<IRiskAssessmentSigneeForm>({
      name: validate(required),
      position: validate(required),
    })
  ),
  change_ids: updateArray(
    updateGroup<IRiskAssessmentChangeForm>({
      name: validate(required),
      ref: validate(required),
    })
  ),
});

export const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(
    RiskAssessmentViewPageActions.setRiskAssessment,
    RiskAssessmentPutPageActions.setRiskAssessment,
    (state, { assessmentId }) => ({
      ...state,
      selectedId: getSelectId(state, assessmentId),
    })
  ),

  on(RiskAssessmentViewPageActions.unsetRiskAssessment, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(
    RiskAssessmentApiActions.setRiskAssessmentForm,
    (state, { riskAssessmentFormValue }) => ({
      ...state,
      form: setValue(state.form, {
        ...state.form.value,
        ...riskAssessmentFormValue,
      }),
    })
  ),

  on(RiskAssessmentPutPageActions.addRiskAssessmentSignee, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      signee_ids: addArrayControl({ ...new RiskAssessmentSignee() }),
    }),
  })),

  on(
    RiskAssessmentPutPageActions.removeRiskAssessmentSignee,
    (state, { id }) => ({
      ...state,
      form: updateGroup(state.form, {
        signee_ids: (signee_ids) => {
          const control = signee_ids.controls.find(
            (control) => control.id === id
          );
          const index = signee_ids.controls.indexOf(control);
          return removeArrayControl(signee_ids, index);
        },
      }),
    })
  ),

  on(RiskAssessmentPutPageActions.addRiskAssessmentChange, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      change_ids: addArrayControl({ ...new RiskAssessmentChange() }),
    }),
  })),

  on(
    RiskAssessmentPutPageActions.removeRiskAssessmentChange,
    (state, { id }) => ({
      ...state,
      form: updateGroup(state.form, {
        change_ids: (change_ids) => {
          const control = change_ids.controls.find(
            (control) => control.id === id
          );
          const index = change_ids.controls.indexOf(control);
          return removeArrayControl(change_ids, index);
        },
      }),
    })
  ),

  on(RiskAssessmentApiActions.riskAssessmentFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(RiskAssessmentPutPageActions.resetsetRiskAssessmentForm, (state) => ({
    ...state,
    form: setValue(reset(state.form), initialRiskAssessmentFormValue),
  })),

  on(RiskAssessmentApiActions.searchRiskAssessments, (state) => ({
    ...state,
    status: StateStatus.Loading,
  })),

  on(
    RiskAssessmentApiActions.searchRiskAssessmentsSuccess,
    (state, { assessments }) =>
      adapter.setAll(assessments, {
        ...state,
        error: null,
        status: StateStatus.Success,
      })
  ),

  on(
    RiskAssessmentApiActions.createRiskAssessmentSuccess,
    RiskAssessmentApiActions.syncRiskAssessmentSuccess,
    (state, { assessment }) =>
      adapter.upsertOne(assessment, {
        ...state,
        error: null,
        status: StateStatus.Success,
      })
  ),

  on(
    RiskAssessmentApiActions.updateRiskAssessmentSuccess,
    (state, { assessment }) =>
      adapter.upsertOne(assessment, {
        ...state,
        error: null,
        status: StateStatus.Success,
        form: markAsPristine(state.form),
      })
  ),

  on(
    RiskAssessmentViewPageActions.openRiskAssessmentReport,
    RiskAssessmentViewPageActions.downloadRiskAssessmentReport,
    (state) => ({
      ...state,
      error: null,
      status: StateStatus.Loading,
    })
  ),

  on(
    RiskAssessmentApiActions.openRiskAssessmentReportSuccess,
    RiskAssessmentApiActions.downloadRiskAssessmentReportSuccess,
    (state) => ({
      ...state,
      error: null,
      status: StateStatus.Success,
    })
  ),

  on(
    RiskAssessmentApiActions.searchRiskAssessmentsFailure,
    RiskAssessmentApiActions.updateRiskAssessmentFailure,
    RiskAssessmentApiActions.openRiskAssessmentReportFailure,
    RiskAssessmentApiActions.downloadRiskAssessmentReportFailure,
    (state, { error }) => ({
      ...state,
      error: error,
      status: StateStatus.Error,
    })
  ),

  on(
    RiskAssessmentSegmentActions.setRiskAssessmentSegment,
    (state, { segment }) => ({
      ...state,
      segment: segment,
    })
  )
);

export const riskAssessmentReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateForm
);

export const selectId = (state: RiskAssessmentState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectForm = (state: RiskAssessmentState) => state.form;

export const selectError = (state: RiskAssessmentState) => state.error;

export const selectLoading = (state: RiskAssessmentState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: RiskAssessmentState) =>
  state.status == StateStatus.Success;

export const selectCanActivate = (state: RiskAssessmentState) =>
  ![StateStatus.Pending, StateStatus.Loading].includes(state.status);

export const selectSegment = (state: RiskAssessmentState) => state.segment;
