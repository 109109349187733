import { ApiId, AppRecord } from "@app/models";

import { EntityState } from "@ngrx/entity";

export enum StateStatus {
  Pending = "pending",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export const getSelectId = (state: EntityState<any>, id: ApiId): string => {
  if (!id) {
    return null;
  }
  const entity = state.ids
    .map((id) => state.entities[id])
    .find((x) => x.id == id || x._id == id);
  return (entity !== undefined && entity._id) || null;
};

export const getApiId = (value: any): string => {
  return value.id || value._id;
};

/**
 *
 * Helper function used in selectors to select related items
 *
 */
export const selectRelatedItems = (item: AppRecord, itemId: ApiId): boolean => {
  if (!item) {
    return false;
  }
  return item._id === itemId || item.id === itemId;
};
