import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setRiskAssessment = createAction(
  "[RiskAssessment Put Page] Set RiskAssessment",
  props<{ assessmentId: ApiId }>()
);

export const resetsetRiskAssessmentForm = createAction(
  "[RiskAssessment Put Page] Reset RiskAssessment Form"
);

export const updateRiskAssessment = createAction(
  "[RiskAssessment Put Page] Update RiskAssessment"
);

export const addRiskAssessmentSignee = createAction(
  "[RiskAssessment Put Page] Add RiskAssessment Signee"
);

export const removeRiskAssessmentSignee = createAction(
  "[RiskAssessment Put Page] Remove RiskAssessment Signee",
  props<{ id: string }>()
);

export const addRiskAssessmentChange = createAction(
  "[RiskAssessment Put Page] Add RiskAssessment Change"
);

export const removeRiskAssessmentChange = createAction(
  "[RiskAssessment Put Page] Remove RiskAssessment Change",
  props<{ id: string }>()
);
