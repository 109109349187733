import { ApiId, ToolboxTalk, ToolboxTalkFormValue } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchToolboxTalksSuccess = createAction(
  "[ToolboxTalk API] Toolbox Talks Search Success",
  props<{ toolboxTalks: ToolboxTalk[] }>()
);

export const searchToolboxTalksFailure = createAction(
  "[ToolboxTalk API] Toolbox Talks Search Failure",
  props<{ error: string }>()
);

export const setOperation = createAction(
  "[ToolboxTalk API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const createToolboxTalkSuccess = createAction(
  "[ToolboxTalk API] Toolbox Talk Create Success",
  props<{ toolboxTalk: ToolboxTalk }>()
);

export const createToolboxTalkFailure = createAction(
  "[ToolboxTalk API] Toolbox Talk Create Failure",
  props<{ error: string }>()
);

export const updateToolboxTalkSuccess = createAction(
  "[ToolboxTalk API] Toolbox Talk Update Success",
  props<{ toolboxTalk: ToolboxTalk }>()
);

export const updateToolboxTalkFailure = createAction(
  "[ToolboxTalk API] Toolbox Talk Update Failure",
  props<{ error: string }>()
);

export const setToolboxTalkForm = createAction(
  "[ToolboxTalk API] Set Toolbox Talk Form",
  props<{
    toolboxTalkFormValue: ToolboxTalkFormValue;
  }>()
);

export const setToolboxTalkTemplate = createAction(
  "[ToolboxTalk API] Set Toolbox Talk Template",
  props<{ toolboxTalkFormValue: ToolboxTalkFormValue }>()
);

export const toolboxTalkFormInvalid = createAction(
  "[ToolboxTalk API] ToolboxTalk Form Invalid"
);

export const setToolboxTalkSuccess = createAction(
  "[ToolboxTalk API] Set Toolbox Talk Success",
  props<{ id: string }>()
);

export const syncToolboxTalkSuccess = createAction(
  "[ToolboxTalk API] Synchronize ToolboxTalk Success",
  props<{ toolboxTalk: ToolboxTalk }>()
);

export const viewToolboxTalk = createAction(
  "[ToolboxTalk API] Open ToolboxTalk View Page",
  props<{ toolboxTalk: ToolboxTalk }>()
);

export const listToolboxTalks = createAction(
  "[ToolboxTalk API] Open Operation ToolboxTalk List Page",
  props<{ operationId: string }>()
);
