import { ApiId, ResUser } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { StateStatus } from "../model";
import { UserApiActions } from "./actions";

export interface UserState extends EntityState<ResUser> {
  selectedId: ApiId;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<ResUser> = createEntityAdapter<ResUser>({
  selectId: (user: ResUser) => user.id,
  sortComparer: false,
});

export const initialState: UserState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const userReducer = createReducer(
  initialState,

  on(UserApiActions.searchUsers, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(UserApiActions.searchUsersSuccess, (state, { users }) =>
    adapter.setAll(users, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(UserApiActions.searchUsersFailure, (state, { error }) => ({
    ...state,
    status: StateStatus.Error,
    error: error,
  }))
);

export const selectId = (state: UserState) => state.selectedId;

export const selectFilter = (state: UserState) => state.filter;
