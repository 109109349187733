import { DiveLogTemplate } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchDiveLogTemplates = createAction(
  "[DiveLogTemplate API] Search DiveLogTemplates"
);

export const searchDiveLogTemplatesSuccess = createAction(
  "[DiveLogTemplate API] DiveLogTemplates Search Success",
  props<{ diveLogTemplates: DiveLogTemplate[] }>()
);

export const searchDiveLogTemplatesFailure = createAction(
  "[DiveLogTemplate API] DiveLogTemplates Search Failure",
  props<{ error: string }>()
);
