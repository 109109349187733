import { RiskAssessment } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  RiskAssessmentSegment,
  adapter,
  selectCanActivate,
  selectError,
  selectForm,
  selectId,
  selectLoaded,
  selectLoading,
  selectSegment,
} from "./risk-assessments.reducer";

export const selectRiskAssessments = (state: AppState) => state.riskAssessments;

export const {
  selectIds: selectRiskAssessmentIds,
  selectEntities: selectRiskAssessmentEntities,
  selectAll: selectAllRiskAssessments,
  selectTotal: selectRiskAssessmentsTotal,
} = adapter.getSelectors(selectRiskAssessments);

export const selectRiskAssessmentsError = createSelector(
  selectRiskAssessments,
  selectError
);

export const selectRiskAssessmentsLoading = createSelector(
  selectRiskAssessments,
  selectLoading
);

export const selectRiskAssessmentsLoaded = createSelector(
  selectRiskAssessments,
  selectLoaded
);

export const selectRiskAssessmentsCanActivate = createSelector(
  selectRiskAssessments,
  selectCanActivate
);

export const selectRiskAssessmentSegment = createSelector(
  selectRiskAssessments,
  selectSegment
);

export const selectRiskAssessmentId = createSelector(
  selectRiskAssessments,
  selectId
);

export const selectRiskAssessmentForm = createSelector(
  selectRiskAssessments,
  selectForm
);

export const selectRiskAssessment = createSelector(
  selectRiskAssessmentEntities,
  selectRiskAssessmentId,
  (entities, selectedId) =>
    (selectedId && entities[selectedId]) ?? new RiskAssessment()
);

export const selectRiskAssessmentHideSignees = createSelector(
  selectRiskAssessmentSegment,
  (segment) => segment !== RiskAssessmentSegment.Signees
);

export const selectRiskAssessmentHideChanges = createSelector(
  selectRiskAssessmentSegment,
  (segment) => segment !== RiskAssessmentSegment.Changes
);
