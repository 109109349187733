import { ApiId, Checklist } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Checklist API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const searchChecklists = createAction(
  "[Checklist API] Search Checklists"
);

export const searchChecklistsSuccess = createAction(
  "[Checklist API] Checklists Search Success",
  props<{ checklists: Checklist[] }>()
);

export const searchChecklistsFailure = createAction(
  "[Checklist API] Checklists Search  Failure",
  props<{ error: string }>()
);

export const createChecklistSuccess = createAction(
  "[Checklist API] Checklist Create Success",
  props<{ checklist: Checklist }>()
);

export const createChecklistFailure = createAction(
  "[Checklist API] Checklist Create Failure",
  props<{ error: string }>()
);

export const syncChecklistSuccess = createAction(
  "[Checklist API] Checklist Synchronize Success",
  props<{ checklist: Checklist }>()
);

export const viewChecklist = createAction(
  "[Checklist API] View Checklist",
  props<{ checklist: Checklist }>()
);

export const listChecklists = createAction(
  "[Checklist API] List Checklists",
  props<{ operationId: ApiId }>()
);
