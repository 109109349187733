import { SafeUrl } from "@angular/platform-browser";
import { ApiId, Attachment, IAttachmentForm } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Attachments API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const searchAttachments = createAction(
  "[Attachments API] Search Attachments"
);

export const searchAttachmentsSuccess = createAction(
  "[Attachments API] Attachments Search Success",
  props<{ attachments: Attachment[] }>()
);

export const searchAttachmentsFailure = createAction(
  "[Attachments API] Attachments Search Failure",
  props<{ error: string }>()
);

export const createAttachmentSuccess = createAction(
  "[Attachments API] Create Attachment Success",
  props<{ attachment: Attachment }>()
);

export const createAttachmentFailure = createAction(
  "[Attachments API] Create Attachment Failure",
  props<{ error: any }>()
);

export const updateAttachmentSuccess = createAction(
  "[Attachments API] Update Attachment Success",
  props<{ attachment: Attachment }>()
);

export const updateAttachmentFailure = createAction(
  "[Attachments API] Update Attachment Failure",
  props<{ error: string }>()
);

export const deleteAttachmentSuccess = createAction(
  "[Attachments API] Attachment Delete Success",
  props<{ attachmentId: ApiId }>()
);

export const deleteAttachmentFailure = createAction(
  "[Attachments API] Attachment Delete Failure",
  props<{ error: string }>()
);

export const syncAttachmentSuccess = createAction(
  "[Attachments API] Synchronize Attachment Success",
  props<{ attachment: Attachment }>()
);

export const setAttachmentForm = createAction(
  "[Attachments API] Set Form Controls",
  props<{ attachmentFormValue: IAttachmentForm; thumbnail: SafeUrl }>()
);

export const attachmentFormInvalid = createAction(
  "[Attachments API] Attachment Form Invalid"
);

export const ensureAttachmentSuccess = createAction(
  "[Attachments API] Ensure Attachent Success",
  props<{ attachment: Attachment }>()
);

export const ensureAttachmentFailure = createAction(
  "[Attachments API] Ensure Attachent Failure",
  props<{ attachment: Attachment; error: any }>()
);
