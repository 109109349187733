import { SegmentValue } from "@ionic/angular";
import { createAction, props } from "@ngrx/store";

export const setSegmentTop = createAction(
  "[DiveLogLine Segment] Set Top Segment",
  props<{ segment: SegmentValue }>()
);

export const setSegmentBottom = createAction(
  "[DiveLogLine Segment] Set Bottom Segment",
  props<{ segment: SegmentValue }>()
);
