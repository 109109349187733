import { SyncInfo } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { adapter, selectStatus } from "./offline-queue.reducer";

export const selectOfflineQueue = (state: AppState) => state.offlineQueue;

export const {
  selectIds: selectOfflineQueueIds,
  selectEntities: selectOfflineQueueEntities,
  selectAll: selectAllOfflineQueues,
  selectTotal: selectTotalOfflineQueues,
} = adapter.getSelectors(selectOfflineQueue);

export const selectOfflineQueueStatus = createSelector(
  selectOfflineQueue,
  selectStatus
);

export const selectEnqueuedGroupSyncInfo = createSelector(
  selectAllOfflineQueues,
  (groups) =>
    groups.map((group) => {
      const parts = group._id.split(":");
      if (parts.length !== 3) {
        throw new Error(`Unexpected enqueued group _id format: ${group._id}`);
      }
      return {
        model: parts[1],
        id: parts[2],
        status: group.status,
        errors: group.errors,
      } as SyncInfo;
    })
);
