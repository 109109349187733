import { ApiId, EnqueuedGroup } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchOfflineQueue = createAction(
  "[Offline Queue API] Search Enqueued Groups"
);

export const searchOfflineQueueSuccess = createAction(
  "[Offline Queue API] Search Enqueued Groups Success",
  props<{ enqueuedGroups: EnqueuedGroup[] }>()
);

export const searchOfflineQueueFailure = createAction(
  "[Offline Queue API] Search Enqueued Groups Failure",
  props<{ error: string }>()
);

export const enqueueRequestSuccess = createAction(
  "[Offline Queue API] Enqueue Request Success",
  props<{ enqueuedGroup: EnqueuedGroup }>()
);

export const processOfflineQueue = createAction(
  "[Offline Queue API] Process Offline Changes"
);

export const processEnqueuedGroup = createAction(
  "[Offline Queue API] Process Equeued Group",
  props<{ enqueuedGroup: EnqueuedGroup }>()
);

export const processEnqueuedGroupSuccess = createAction(
  "[Offline Queue API] Process Equeued Group Success",
  props<{ enqueuedGroup: EnqueuedGroup }>()
);

export const processEnqueuedGroupFailure = createAction(
  "[Offline Queue API] Process Equeued Group Failure",
  props<{ enqueuedGroup: EnqueuedGroup }>()
);

export const deleteEnqueuedGroup = createAction(
  "[Offline Queue API] Delete Enqueued Request",
  props<{ id: string }>()
);

export const ApiCreateSuccess = createAction(
  "[Offline Queue API] Create Success",
  props<{ model: string; data: any }>()
);

export const ApiUpdateSuccess = createAction(
  "[Offline Queue API] Update Success",
  props<{ model: string; data: any }>()
);

export const ApiDeleteSuccess = createAction(
  "[Offline Queue API] Delete Success",
  props<{ model: string; id: ApiId }>()
);
