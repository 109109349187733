import { AuthUser } from "@app/models";
import { createReducer, on } from "@ngrx/store";
import {
  FormGroupState,
  createFormGroupState,
  onNgrxForms,
  setValue,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { AuthApiActions, AuthPageActions } from "../actions";
import { StateStatus } from "../model";

export const FORM_ID = "auth";

export interface AuthForm {
  username: string;
  password: string;
}

export interface AuthState {
  loggedIn: boolean;
  user: AuthUser;
  status: StateStatus;
  error: string;
  form: FormGroupState<AuthForm>;
}

const initialForm: AuthForm = {
  username: null,
  password: null,
};

const initialState: AuthState = {
  loggedIn: false,
  user: null,
  status: null,
  error: null,
  form: createFormGroupState<AuthForm>(FORM_ID, initialForm),
};

const validateForm = updateGroup<AuthForm>({
  username: validate(required),
  password: validate(required),
});

const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(AuthApiActions.init, (state, { loggedIn, user }) => ({
    ...state,
    loggedIn: loggedIn,
    user: user,
    form: updateGroup(state.form, {
      username: setValue(user.login),
    }),
  })),

  on(AuthPageActions.login, (state) => ({
    ...state,
    status: StateStatus.Pending,
    error: null,
  })),

  on(AuthApiActions.loginSuccess, (state, { loggedIn, user }) => ({
    ...state,
    loggedIn: loggedIn,
    user: user,
    status: StateStatus.Success,
    error: null,
  })),

  on(AuthApiActions.loginFailure, (state, { error }) => ({
    ...state,
    loggedIn: false,
    status: StateStatus.Error,
    error: error,
  }))
);

export const authReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateForm
);

export const selectForm = (state: AuthState) => state.form;

export const selectError = (state: AuthState) => state.error;

export const selectLoading = (state: AuthState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: AuthState) =>
  state.status == StateStatus.Success;
