import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Operation Timesheets List Page] Set Operation",
  props<{ operationId: ApiId }>()
);

export const updateTimesheets = createAction(
  "[Operation Timesheets List Page] Open Operation Timesheets Put Page"
);
