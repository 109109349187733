import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Operation Put Page] Set Operation",
  props<{ operationId: string }>()
);

export const newOperation = createAction("[Operation Put Page] New Operation");

export const createOperation = createAction(
  "[Operations Put Page] Create Operation"
);

export const updateOperation = createAction(
  "[Operations Put Page] Update Operation"
);

export const resetOperationForm = createAction(
  "[Operations Put Page] Reset Form"
);

export const setOperationFormCustomer = createAction(
  "[Operations Put Page] Set Operation Form Customer"
);
