import {
  ApiId,
  DIVE_LOG_LINE_MODEL,
  DiveLogLine,
  IDiveLogLineForm,
  ITimesheetForm,
} from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  addArrayControl,
  box,
  createFormGroupState,
  FormGroupState,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  removeArrayControl,
  reset,
  setValue,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { getSelectId, StateStatus } from "../model";
import {
  DiveLogLineApiActions,
  DiveLogLinePutPageActions,
  DiveLogLineSegmentActions,
  DiveLogLineViewPageActions,
} from "./actions";

export type SegmentTop = "supervisor" | "pre_dive" | "dive" | "activities";
export type SegmentBottom = "diver1" | "diver2" | "standby" | "all";

export interface DiveLogLineState extends EntityState<DiveLogLine> {
  selectedId: ApiId;
  selectedSegmentTop: SegmentTop;
  selectedSegmentBottom: SegmentBottom;
  error: string;
  status: StateStatus;
  form: FormGroupState<IDiveLogLineForm>;
}

export const initialDiveLogLineFormValue: IDiveLogLineForm = {
  log_id: null,

  template_id: box(null),
  signature: null,

  diver_1_id: box(null),
  diver_2_id: box(null),
  standby_id: box(null),

  diver_1_signature_pre: null,
  diver_2_signature_pre: null,
  standby_signature_pre: null,

  diver_1_signature_post: null,
  diver_2_signature_post: null,
  standby_signature_post: null,

  item_ids: [],
  time_sheet_ids: [],
};

export const adapter: EntityAdapter<DiveLogLine> =
  createEntityAdapter<DiveLogLine>({
    selectId: (diveLogLine: DiveLogLine) => diveLogLine._id,
    sortComparer: false,
  });

export const initialState: DiveLogLineState = adapter.getInitialState({
  selectedId: null,
  selectedSegmentTop: "supervisor",
  selectedSegmentBottom: "diver1",
  error: null,
  status: StateStatus.Pending,
  form: createFormGroupState<IDiveLogLineForm>(
    DIVE_LOG_LINE_MODEL,
    initialDiveLogLineFormValue
  ),
});

const validateFormState = updateGroup({
  time_sheet_ids: updateArray(
    updateGroup<ITimesheetForm>({
      name: validate(required),
      time: validate(required),
    })
  ),
});

export const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(
    DiveLogLineViewPageActions.setDiveLogLine,
    DiveLogLinePutPageActions.setDiveLogLine,
    (state, { logLineId }) => ({
      ...state,
      selectedId: getSelectId(state, logLineId),
    })
  ),

  on(DiveLogLineViewPageActions.unsetDiveLogLine, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(DiveLogLineApiActions.searchDiveLogLines, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    DiveLogLineApiActions.searchDiveLogLinesSuccess,
    (state, { diveLogLines }) =>
      adapter.setAll(diveLogLines, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    DiveLogLinePutPageActions.createDiveLogLine,
    DiveLogLinePutPageActions.updateDiveLogLine,
    (state) => ({
      ...state,
      status: StateStatus.Loading,
      error: null,
    })
  ),

  on(
    DiveLogLineApiActions.createDiveLogLineSuccess,
    DiveLogLineApiActions.updateDiveLogLineSuccess,
    (state, { diveLogLine }) =>
      adapter.upsertOne(diveLogLine, {
        ...state,
        status: StateStatus.Success,
        error: null,
        form: markAsPristine(state.form),
      })
  ),

  on(DiveLogLineApiActions.syncDiveLogLineSuccess, (state, { diveLogLine }) =>
    adapter.upsertOne(diveLogLine, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(
    DiveLogLineApiActions.searchDiveLogLinesFailure,
    DiveLogLineApiActions.createDiveLogLineFailure,
    DiveLogLineApiActions.updateDiveLogLineFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(DiveLogLineSegmentActions.setSegmentTop, (state, { segment }) => ({
    ...state,
    selectedSegmentTop: segment as SegmentTop,
  })),

  on(DiveLogLineSegmentActions.setSegmentBottom, (state, { segment }) => ({
    ...state,
    selectedSegmentBottom: segment as SegmentBottom,
  })),

  on(
    DiveLogLineApiActions.setDiveLogLineForm,
    DiveLogLineApiActions.setDiveLogLineTemplate,
    (state, { diveLogLineFormValue }) => ({
      ...state,
      form: setValue(state.form, {
        ...state.form.value,
        ...diveLogLineFormValue,
      }),
    })
  ),

  on(DiveLogLineApiActions.addTimesheetControl, (state, { timesheet }) => ({
    ...state,
    form: updateGroup(state.form, {
      time_sheet_ids: addArrayControl(timesheet),
    }),
  })),

  on(DiveLogLinePutPageActions.remTimesheet, (state, { id }) => ({
    ...state,
    form: updateGroup(state.form, {
      time_sheet_ids: (time_sheet_ids) => {
        const control = time_sheet_ids.controls.find(
          (control) => control.id === id
        );
        const index = time_sheet_ids.controls.indexOf(control);
        return removeArrayControl(time_sheet_ids, index);
      },
    }),
  })),

  on(DiveLogLinePutPageActions.resetSignature, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      signature: setValue(<string>null),
    }),
  })),

  on(DiveLogLineApiActions.diveLogLineFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(DiveLogLinePutPageActions.resetDiveLogLineForm, (state) => ({
    ...state,
    form: setValue(reset(state.form), initialDiveLogLineFormValue),
  }))
);

export const diveLogLineReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateFormState
);

export const selectId = (state: DiveLogLineState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectStatus = (state: DiveLogLineState) => state.status;

export const selectForm = (state: DiveLogLineState) => state.form;

export const selectError = (state: DiveLogLineState) => state.error;

export const selectLoading = (state: DiveLogLineState) =>
  state.status == "loading";

export const selectLoaded = (state: DiveLogLineState) =>
  state.status == StateStatus.Success;

export const selectSegmentTop = (state: DiveLogLineState) =>
  state.selectedSegmentTop;

export const selectSegmentBottom = (state: DiveLogLineState) =>
  state.selectedSegmentBottom;
