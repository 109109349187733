import { IToolboxTalkGroup, ToolboxTalk, ToolboxTalkItem } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectCanActivate,
  selectError,
  selectForm,
  selectId,
  selectLoaded,
  selectLoading,
  selectSegment,
} from "./toolbox-talk.reducer";

export const selectToolboxTalks = (state: AppState) => state.toolboxTalks;

export const {
  selectIds: selectToolboxTalkIds,
  selectEntities: selectToolboxTalkEntities,
  selectAll: selectAllToolboxTalks,
  selectTotal: selectTotalToolboxTalks,
} = adapter.getSelectors(selectToolboxTalks);

export const selectToolboxTalkId = createSelector(selectToolboxTalks, selectId);

export const selectToolboxTalk = createSelector(
  selectToolboxTalkEntities,
  selectToolboxTalkId,
  (entities, selectedId) =>
    (!!selectedId && entities[selectedId]) || new ToolboxTalk()
);

export const selectToolboxTalkForm = createSelector(
  selectToolboxTalks,
  selectForm
);

export const selectToolboxTalksLoaded = createSelector(
  selectToolboxTalks,
  selectLoaded
);

export const selectToolboxTalksLoading = createSelector(
  selectToolboxTalks,
  selectLoading
);

export const selectToolboxTalksError = createSelector(
  selectToolboxTalks,
  selectError
);

export const selectToolboxTalksCanActivate = createSelector(
  selectToolboxTalks,
  selectCanActivate
);

export const selectToolboxTalkSegment = createSelector(
  selectToolboxTalks,
  selectSegment
);

export const selectToolboxTalkHideAttendess = createSelector(
  selectToolboxTalkSegment,
  (segment) => segment !== "attendees"
);

export const selectToolboxTalkHideTopics = createSelector(
  selectToolboxTalkSegment,
  (segment) => segment !== "topics"
);

export const selectToolboxTalkGroups = createSelector(
  selectToolboxTalk,
  (toolboxTalk) =>
    toolboxTalk.item_ids.reduce((previous, current: ToolboxTalkItem) => {
      const group = previous.find((group) => group.id == current.group_id.id);
      if (group) {
        group.item_ids.push(current);
      } else {
        previous.push({
          id: current.group_id.id,
          name: current.group_id.name,
          item_ids: [current],
        });
      }
      return previous;
    }, <IToolboxTalkGroup[]>[])
);
