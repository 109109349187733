import {
  DIVE_OPERATION_MODEL,
  DiveOperation,
  IMany2One,
  SyncInfo,
  Timesheet,
  ToolboxTalkAttendee,
} from "@app/models";
import { timeStringToSeconds } from "@app/shared/utils";
import { createSelector } from "@ngrx/store";
import { unbox } from "ngrx-forms";
import { AppState } from "../app.state";
import { selectAllAttachments } from "../attachments/attachments.selectors";
import { selectLoggedUser } from "../auth/auth.selectors";
import { selectState } from "../checklist-lines/checklist-lines.reducer";
import { selectAllChecklistLines } from "../checklist-lines/checklist-lines.selectors";
import { selectAllChecklists } from "../checklists/checklists.selectors";
import { selectAllDiveJobs } from "../dive-jobs/dive-jobs.selectors";
import { selectAllDiveLogLines } from "../dive-log-lines/dive-log-lines.selectors";
import { selectAllDiveLogs } from "../dive-logs/dive-logs.selectors";
import { selectAllDiveSites } from "../dive-sites/dive-sites.selectors";
import { selectRelatedItems } from "../model";
import { selectEnqueuedGroupSyncInfo } from "../offline-queue/offline-queue.selectors";
import { selectAllPartners } from "../partners/partners.selectors";
import { selectAllRiskAssessments } from "../risk-assessments/risk-assessments.selectors";
import { selectAllToolboxTalks } from "../toolbox-talks/toolbox-talk.selectors";
import { selectAllUsers } from "../users/users.selectors";
import {
  adapter,
  selectCanActivate,
  selectError,
  selectFilter,
  selectForm,
  selectId,
  selectLoaded,
  selectLoading,
  selectPending,
} from "./operations.reducer";

export const selectOperations = (state: AppState) => state.operations;

export const {
  selectIds: selectOperationIds,
  selectEntities: _selectOperationEntities,
  selectAll: _selectAllOperations,
  selectTotal: selectTotalOperations,
} = adapter.getSelectors(selectOperations);

export const selectOperationsPending = createSelector(
  selectOperations,
  selectPending
);

export const selectOperationsLoading = createSelector(
  selectOperations,
  selectLoading
);

export const selectOperationsLoaded = createSelector(
  selectOperations,
  selectLoaded
);

export const selectOperationsCanActivate = createSelector(
  selectOperations,
  selectCanActivate
);

export const selectOperationsError = createSelector(
  selectOperations,
  selectError
);

export const selectOperationFilter = createSelector(
  selectOperations,
  selectFilter
);

export const selectOperationEntities = createSelector(
  _selectOperationEntities,
  selectEnqueuedGroupSyncInfo,
  (_entities, enqueuedGroups) => {
    const enqueuedGroupMap = enqueuedGroups
      .filter((group) => group.model === DIVE_OPERATION_MODEL)
      .reduce((accumulator: Record<string, SyncInfo>, group) => {
        accumulator[group.id] = {
          status: group.status,
          errors: group.errors,
        };
        return accumulator;
      }, {});

    return Object.keys(_entities).reduce((accumulator, operationId) => {
      const _operation = _entities[operationId];
      const operation: DiveOperation = {
        ..._operation,
        sync_status: enqueuedGroupMap[_operation._id]?.status,
        sync_errors: enqueuedGroupMap[_operation._id]?.errors,
      };
      accumulator[operationId] = operation;
      return accumulator;
    }, {});
  }
);

export const selectAllOperations = createSelector(
  _selectAllOperations,
  selectEnqueuedGroupSyncInfo,
  (operations, enqueuedGroups) => {
    const enqueuedGroupMap = enqueuedGroups
      .filter((group) => group.model === DIVE_OPERATION_MODEL)
      .reduce((accumulator: Record<string, SyncInfo>, group) => {
        accumulator[group.id] = {
          status: group.status,
          errors: group.errors,
        };
        return accumulator;
      }, {});

    return operations.map((operation) => {
      return new DiveOperation({
        ...operation,
        sync_status: enqueuedGroupMap[operation._id]?.status,
        sync_errors: enqueuedGroupMap[operation._id]?.errors,
      });
    });
  }
);

export const selectOperationList = createSelector(
  selectAllOperations,
  selectOperationFilter,
  (operations, filter) => {
    if (!filter || filter.trim() === "") {
      return operations;
    } else {
      const lowerCaseFilter = filter.toLowerCase().trim();
      return operations.filter((operation) => {
        const nameMatches = operation.name
          ?.toLowerCase()
          .includes(lowerCaseFilter);
        const jobNameMatches = operation.job_id?.name
          ?.toLowerCase()
          .includes(lowerCaseFilter);
        const siteNameMatches = operation.site_id?.name
          ?.toLowerCase()
          .includes(lowerCaseFilter);
        const partnerNameMatches = operation.partner_id?.name
          ?.toLowerCase()
          .includes(lowerCaseFilter);
        const supervisorNameMatches = operation.supervisor_id?.name
          ?.toLowerCase()
          .includes(lowerCaseFilter);

        return (
          nameMatches ||
          jobNameMatches ||
          siteNameMatches ||
          partnerNameMatches ||
          supervisorNameMatches
        );
      });
    }
  }
);

export const selectOperationId = createSelector(selectOperations, selectId);

export const selectOperationForm = createSelector(selectOperations, selectForm);

export const selectOperation = createSelector(
  selectOperationEntities,
  selectOperationId,
  (entities, selectedId) =>
    (!!selectedId && entities[selectedId]) || new DiveOperation()
);

export const selectOperationSupervisor = createSelector(
  selectOperation,
  (operation) => operation.supervisor_id
);

export const selectOperationSyncing = createSelector(
  selectOperation,
  (operation) => operation && operation.sync_status == "syncing"
);

export const selectOperationDivers = createSelector(
  selectOperation,
  (operation) => operation.diver_ids
);

export const selectOperationOpen = createSelector(
  selectOperation,
  (operation) => operation && operation.state !== "done"
);

export const selectOperationSyncOptions = createSelector(
  selectOperationId,
  (operationId) => ({ model: "dive.operation", id: operationId })
);

export const selectOperationRiskAssessments = createSelector(
  selectAllRiskAssessments,
  selectOperation,
  (assessments, operation) =>
    assessments.filter((assessment) =>
      selectRelatedItems(operation, assessment.operation_id)
    )
);

// TODO what indicates that risk assessments are done?
export const selectOperationRiskAssessmentsDone = createSelector(
  selectOperationRiskAssessments,
  (assessments) => false
);

export const selectOperationChecklists = createSelector(
  selectAllChecklists,
  selectOperation,
  (checklists, operation) =>
    checklists.filter((checklist) =>
      selectRelatedItems(operation, checklist.operation_id)
    )
);

export const selectOperationChecklistsDone = createSelector(
  selectOperationChecklists,
  (checklists) =>
    checklists.length > 0 &&
    checklists.every((checklist) => checklist.state == "done")
);

export const selectOperationDiveLogs = createSelector(
  selectAllDiveLogs,
  selectOperation,
  (diveLogs, operation) =>
    diveLogs.filter((diveLog) =>
      selectRelatedItems(operation, diveLog.operation_id)
    )
);

export const selectOperationDiveLogLines = createSelector(
  selectAllDiveLogLines,
  selectOperation,
  (diveLogLines, operation) =>
    operation &&
    diveLogLines.filter(
      (diveLogLine) =>
        diveLogLine.operation_id == operation._id ||
        diveLogLine.operation_id == operation.id
    )
);

export const selectOperationDiveLogLinesDone = createSelector(
  selectOperationDiveLogLines,
  (diveLogLines) =>
    diveLogLines.length > 0 &&
    diveLogLines.every((diveLogLine) => !!diveLogLine.signature)
);

export const selectOperationDiveLogLineDiversPicker = createSelector(
  selectOperationDivers,
  (divers) =>
    divers.map((diver) => <IMany2One>{ id: diver.id, name: diver.name })
);

export const selectOperationWorklog = createSelector(
  selectOperation,
  selectOperationDiveLogLines,
  (operation, diveLogLines) => {
    const activities: Timesheet[] = [];
    diveLogLines.forEach((diveLogLine) => {
      diveLogLine.item_ids
        .filter((logItem) => logItem.is_activity)
        .forEach((logItem) => {
          if (logItem.value_1_time) {
            activities.push(
              new Timesheet({
                name: `${diveLogLine.diver_1_id.name} ${logItem.name}`,
                time: logItem.value_1_time,
              })
            );
          }
          if (logItem.value_2_time) {
            activities.push(
              new Timesheet({
                name: `${diveLogLine.diver_2_id.name} ${logItem.name}`,
                time: logItem.value_2_time,
              })
            );
          }
          if (logItem.value_3_time) {
            activities.push(
              new Timesheet({
                name: `${diveLogLine.standby_id.name} ${logItem.name}`,
                time: logItem.value_3_time,
              })
            );
          }
        });
    });
    return <Timesheet[]>(
      [...operation.time_sheet_ids, ...activities].sort(
        (a, b) => timeStringToSeconds(a.time) - timeStringToSeconds(b.time)
      )
    );
  }
);

export const selectOperationChecklistLines = createSelector(
  selectAllChecklistLines,
  selectOperation,
  (checklistLines, operation) =>
    checklistLines.filter((checklistLine) =>
      selectRelatedItems(operation, checklistLine.operation_id)
    )
);

export const selectOperationChecklistLinesDone = createSelector(
  selectOperationChecklistLines,
  (checklistLines) =>
    checklistLines.length > 0 &&
    checklistLines.every(
      (checklistLine) => selectState(checklistLine) == "done"
    )
);

export const selectOperationToolboxTalks = createSelector(
  selectAllToolboxTalks,
  selectOperation,
  (toolboxTalks, operation) => {
    return toolboxTalks.filter((toolboxTalk) =>
      selectRelatedItems(operation, toolboxTalk.operation_id)
    );
  }
);

export const selectOperationToolboxTalkAttendees = createSelector(
  selectOperationToolboxTalks,
  (toolboxTalks) =>
    toolboxTalks
      .map((toolboxTalk) => toolboxTalk.attendee_ids)
      .reduce((previous, current) => previous.concat(current), [])
);

export const selectOperationToolboxTalksDone = createSelector(
  selectOperationToolboxTalks,
  selectOperationToolboxTalkAttendees,
  (toolboxTalks, toolboxTalkAttendees) =>
    toolboxTalks.length > 0 &&
    toolboxTalks.every(
      (toolboxTalk) =>
        toolboxTalk &&
        !!toolboxTalk.signature &&
        toolboxTalkAttendees.every(
          (attendee: ToolboxTalkAttendee) => !!attendee.signature
        )
    )
);

export const selectOperationAttachments = createSelector(
  selectAllAttachments,
  selectOperation,
  (attachments, operation) =>
    operation &&
    attachments.filter((attachment) =>
      selectRelatedItems(operation, attachment.res_id)
    )
);

export const selectOperationFormPartner = createSelector(
  selectOperationForm,
  (form) => unbox(form.controls.partner_id.value)
);

export const selectOperationFormPartnerId = createSelector(
  selectOperationFormPartner,
  (partner) => (partner !== null ? partner.id : null)
);

export const selectOperationAllowedJobs = createSelector(
  selectAllDiveJobs,
  (jobs) => jobs.map((job) => <IMany2One>{ id: job.id, name: job.name })
);

export const selectOperationAllowedCustomers = createSelector(
  selectAllPartners,
  (partners) =>
    partners
      .filter((partner) => partner.is_company && partner.type == "contact")
      .map((partner) => <IMany2One>{ id: partner.id, name: partner.name })
);

export const selectOperationAllowedDiveSites = createSelector(
  selectAllDiveSites,
  selectOperationFormPartnerId,
  (sites, partner_id) =>
    sites
      .filter(
        (site) =>
          partner_id &&
          (site.partner_id == partner_id || site.partner_id == null)
      )
      .map((site) => <IMany2One>{ id: site.id, name: site.name })
);

export const selectOperationAllowedSupervisors = createSelector(
  selectAllUsers,
  selectLoggedUser,
  (users, loggedUser) =>
    users
      .filter(
        (user) =>
          (loggedUser.role === "operation_manager" && user) ||
          // TODO refactor after coping with record.id type
          user.id.toString() === loggedUser.id.toString()
      )
      .map((user) => <any>{ id: user.id, name: user.name })
);

export const selectOperationAllowedDivers = createSelector(
  selectAllUsers,
  (users) => users.map((users) => <IMany2One>{ id: users.id, name: users.name })
);
