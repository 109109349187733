import {
  ApiId,
  IToolboxTalkForm,
  TOOLBOX_TALK_MODEL,
  ToolboxTalk,
  ToolboxTalkAttendee,
  ToolboxTalkItem,
} from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  addArrayControl,
  box,
  createFormGroupState,
  FormGroupState,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  removeArrayControl,
  reset,
  setValue,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { getApiId, getSelectId, StateStatus } from "../model";
import {
  ToolboxTalkApiActions,
  ToolboxTalkListPageActions,
  ToolboxTalkPutPageActions,
  ToolboxTalkSegmentActions,
  ToolboxTalkViewPageActions,
} from "./actions";

export interface ToolboxTalkState extends EntityState<ToolboxTalk> {
  selectedId: ApiId;
  segment: "topics" | "attendees";
  error: string;
  status: StateStatus;
  form: FormGroupState<IToolboxTalkForm>;
}

export const adapter: EntityAdapter<ToolboxTalk> =
  createEntityAdapter<ToolboxTalk>({
    selectId: (talk: ToolboxTalk) => talk._id,
    sortComparer: false,
  });

export const initialToolboxTalkFormValue: IToolboxTalkForm = {
  template_id: box(null),
  speaker_name: null,
  speaker_position: null,
  signature: null,
  item_ids: [],
  attendee_ids: [],
};

export const initialState: ToolboxTalkState = adapter.getInitialState({
  selectedId: null,
  form: createFormGroupState<IToolboxTalkForm>(
    TOOLBOX_TALK_MODEL,
    initialToolboxTalkFormValue
  ),
  segment: "topics",
  error: null,
  filter: [],
  status: StateStatus.Pending,
});

const validateFormState = updateGroup({
  speaker_name: validate(required),
  speaker_position: validate(required),
  item_ids: updateArray(
    updateGroup({
      name: validate(required),
      type: validate(required),
      group_id: validate(required),
    })
  ),
  attendee_ids: updateArray(
    updateGroup({
      name: validate(required),
      position: validate(required),
    })
  ),
});

export const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(
    ToolboxTalkViewPageActions.setToolboxTalk,
    ToolboxTalkPutPageActions.setToolboxTalk,
    (state, { talkId }) => ({
      ...state,
      selectedId: getSelectId(state, talkId),
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(ToolboxTalkViewPageActions.unsetToolboxTalk, (state) => ({
    ...state,
    selectedId: null,
    status: StateStatus.Success,
  })),

  on(
    ToolboxTalkApiActions.viewToolboxTalk,
    ToolboxTalkApiActions.createToolboxTalkSuccess,
    (state, { toolboxTalk }) => ({
      ...state,
      selectedId: getApiId(toolboxTalk),
      status: StateStatus.Success,
    })
  ),

  on(ToolboxTalkListPageActions.searchToolboxTalks, (state) => ({
    ...state,
    status: StateStatus.Loading,
  })),

  on(
    ToolboxTalkApiActions.searchToolboxTalksSuccess,
    (state, { toolboxTalks }) =>
      adapter.setAll(toolboxTalks, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(ToolboxTalkSegmentActions.setToolboxTalkSegment, (state, { segment }) => ({
    ...state,
    segment: segment,
  })),

  on(
    ToolboxTalkApiActions.createToolboxTalkSuccess,
    ToolboxTalkApiActions.syncToolboxTalkSuccess,
    (state, { toolboxTalk }) =>
      adapter.upsertOne(toolboxTalk, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(ToolboxTalkApiActions.updateToolboxTalkSuccess, (state, { toolboxTalk }) =>
    adapter.upsertOne(toolboxTalk, {
      ...state,
      status: StateStatus.Success,
      error: null,
      form: markAsPristine(state.form),
    })
  ),

  on(ToolboxTalkPutPageActions.updateToolboxTalk, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    ToolboxTalkApiActions.searchToolboxTalksFailure,
    ToolboxTalkApiActions.updateToolboxTalkFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(
    ToolboxTalkApiActions.setToolboxTalkForm,
    ToolboxTalkApiActions.setToolboxTalkTemplate,
    (state, { toolboxTalkFormValue }) => ({
      ...state,
      form: setValue(state.form, {
        ...state.form.value,
        ...toolboxTalkFormValue,
      }),
    })
  ),

  on(ToolboxTalkPutPageActions.addTopic, (state, { group_id, name }) => ({
    ...state,
    form: updateGroup(state.form, {
      item_ids: (item_ids) =>
        addArrayControl(
          item_ids,
          {
            ...new ToolboxTalkItem({
              name: name,
              type: "boolean",
              group_id: group_id,
              value_1_boolean: true,
            }),
          },
          item_ids.value
            .map(
              (value) => "group_id" in value && value.group_id.id == group_id.id
            )
            .lastIndexOf(true) + 1
        ),
    }),
  })),

  on(ToolboxTalkPutPageActions.addAttendee, (state) => ({
    ...state,
    form: updateGroup(state.form, {
      attendee_ids: (attendee_ids) =>
        addArrayControl(attendee_ids, { ...new ToolboxTalkAttendee() }),
    }),
  })),

  on(ToolboxTalkPutPageActions.remAttendee, (state, { id }) => ({
    ...state,
    form: updateGroup(state.form, {
      attendee_ids: (attendee_ids) => {
        const control = attendee_ids.controls.find(
          (control) => control.id === id
        );
        const index = attendee_ids.controls.indexOf(control);
        return removeArrayControl(attendee_ids, index);
      },
    }),
  })),

  on(ToolboxTalkApiActions.toolboxTalkFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(ToolboxTalkPutPageActions.resetToolboxTalkForm, (state) => ({
    ...state,
    status: StateStatus.Success,
    error: null,
    form: setValue(reset(state.form), initialToolboxTalkFormValue),
  }))
);

export const toolboxTalkReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateFormState
);

export const selectId = (state: ToolboxTalkState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectForm = (state: ToolboxTalkState) => state.form;

export const selectError = (state: ToolboxTalkState) => state.error;

export const selectLoading = (state: ToolboxTalkState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: ToolboxTalkState) =>
  state.status == StateStatus.Success;

export const selectCanActivate = (state: ToolboxTalkState) =>
  ![StateStatus.Pending, StateStatus.Loading].includes(state.status);

export const selectSegment = (state: ToolboxTalkState) => state.segment;
