import { SafeUrl } from "@angular/platform-browser";
import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setAttachment = createAction(
  "[Attachments Put Page] Set Attachment",
  props<{ attachmentId: ApiId }>()
);

export const setOperation = createAction(
  "[Attachments Put Page] Set Operation",
  props<{ operationId: ApiId }>()
);

export const setAttachmentFile = createAction(
  "[Attachments Put Page] Set Attachment File",
  props<{ fileBlob: any }>()
);

export const setAttachmentFileSuccess = createAction(
  "[Attachments Put Page] Set Attachment File Success",
  props<{ name: string; mimetype: string; thumbnail: SafeUrl }>()
);

export const createAttachment = createAction(
  "[Attachments Put Page] Create Attachment"
);

export const updateAttachment = createAction(
  "[Attachments Put Page] Update Attachment"
);

export const resetForm = createAction("[Attachments API] Reset Form");
