import { IMany2One } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectFilter,
  selectId,
} from "./toolbox-talk-templates.reducer";

export const selectToolboxTalkTemplates = (state: AppState) =>
  state.toolboxTalkTemplates;

export const {
  selectIds: selectToolboxTalkTemplateIds,
  selectEntities: selectToolboxTalkTemplateEntities,
  selectAll: selectAllToolboxTalkTemplates,
  selectTotal: selectToolboxTalkTemplatesTotal,
} = adapter.getSelectors(selectToolboxTalkTemplates);

export const selectToolboxTalkTemplateId = createSelector(
  selectToolboxTalkTemplates,
  selectId
);

export const selectToolboxTalkTemplate = createSelector(
  selectToolboxTalkTemplateEntities,
  selectToolboxTalkTemplateId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectToolboxTalkTemplateFilter = createSelector(
  selectToolboxTalkTemplates,
  selectFilter
);

export const selectSelectableToolboxTalkTemplates = createSelector(
  selectAllToolboxTalkTemplates,
  selectToolboxTalkTemplateFilter,
  (templates, filter) =>
    templates
      .filter((template) => (filter ? template.name.includes(filter) : true))
      .map((template) => <IMany2One>{ id: template.id, name: template.name })
);

export const selectDefaultToolboxTalkTemplate = createSelector(
  selectAllToolboxTalkTemplates,
  (templates) => templates.length && templates[0]
);
