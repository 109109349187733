import { ApiId, DiveOperation, DiveOperationState } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Operation View Page] Set Operation",
  props<{ operationId: ApiId }>()
);

export const unsetOperation = createAction(
  "[Operation View Page] Unset Operation"
);

export const editOperation = createAction(
  "[Operation View Page] Edit Operation",
  props<{ operation: DiveOperation }>()
);

export const updateOperationState = createAction(
  "[Operation View Page] Update Operation State",
  props<{ state: DiveOperationState }>()
);

export const openRiskAssessments = createAction(
  "[Operation View Page] Open RisksAssessments"
);

export const openToolboxTalks = createAction(
  "[Operation View Page] Open ToolboxTalks"
);

export const openChecklists = createAction(
  "[Operation View Page] Open Checklists"
);

export const openDiveLogs = createAction("[Operation View Page] Open DiveLogs");

export const openTimesheets = createAction(
  "[Operation View Page] Open Operation Timesheets List Page"
);

export const openAttachments = createAction(
  "[Operation View Page] Open Operation Attachments List Page"
);
