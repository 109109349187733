import { ApiId, DiveSite } from "@app/models";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { StateStatus } from "../model";
import { DiveSiteApiActions } from "./actions";

export interface DiveSiteState extends EntityState<DiveSite> {
  selectedId: ApiId;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<DiveSite> = createEntityAdapter<DiveSite>({
  selectId: (diveSite: DiveSite) => diveSite.id,
  sortComparer: false,
});

export const initialState: DiveSiteState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const diveSiteReducer = createReducer(
  initialState,

  on(DiveSiteApiActions.searchDiveSites, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(DiveSiteApiActions.searchDiveSitesSuccess, (state, { diveSites }) =>
    adapter.setAll(diveSites, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(DiveSiteApiActions.searchDiveSitesFailure, (state, { error }) => ({
    ...state,
    status: StateStatus.Error,
    error: error,
  }))
);

export const selectId = (state: DiveSiteState) => state.selectedId;

export const selectFilter = (state: DiveSiteState) => state.filter;
