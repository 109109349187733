import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setChecklist = createAction(
  "[ChecklistLine Put Page] Set Checklist For Create",
  props<{ checklistId: ApiId }>()
);

export const setTemplate = createAction(
  "[ChecklistLine Put Page] Set Template",
  props<{ id: number }>()
);

export const setChecklistLine = createAction(
  "[ChecklistLine Put Page] Set ChecklistLine",
  props<{ checklistLineId: ApiId }>()
);

export const resetSignature = createAction(
  "[ChecklistLine Put Page] Reset Signature"
);

export const createChecklistLine = createAction(
  "[ChecklistLine Put Page] Create ChecklistLine"
);

export const updateChecklistLine = createAction(
  "[ChecklistLine Put Page] Update ChecklistLine"
);

export const resetChecklistLineForm = createAction(
  "[ChecklistLine Put Page] Reset Form"
);
