import { DiveOperation } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchOperations = createAction(
  "[Operations List Page] Search Operations",
  props<{ filter?: string }>()
);

export const setFilter = createAction(
  "[Operations List Page] Set Filter",
  props<{ filter: string }>()
);

export const viewOperation = createAction(
  "[Operations List Page] Open Operation View Page]",
  props<{ operation: DiveOperation }>()
);

export const createOperation = createAction(
  "[Operations List Page] Open Operation Create Page]"
);

export const archiveOperation = createAction(
  "[Operations List Page] Archive Operation]",
  props<{ id: string }>()
);
