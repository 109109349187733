import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setDiveLogLine = createAction(
  "[DiveLogLine Put Page] Set Update DiveLogLine",
  props<{ logLineId: ApiId }>()
);

export const setDiveLog = createAction(
  "[DiveLogLine Put Page] Set DiveLog",
  props<{ logId: ApiId }>()
);

export const setTemplate = createAction(
  "[DiveLogLine Put Page] Set Template",
  props<{ id: ApiId }>()
);

export const addTimesheet = createAction(
  "[DiveLogLine Put Page] Add Timesheet",
  props<{ name?: string; time?: string; description?: string }>()
);

export const remTimesheet = createAction(
  "[DiveLogLine Put Page] Remove Timesheet",
  props<{ id: string }>()
);

export const resetDiveLogLineForm = createAction(
  "[DiveLogLine Put Page] Reset DiveLogLine Form"
);

export const resetSignature = createAction(
  "[DiveLogLine Put Page] Reset Signature"
);

export const createDiveLogLine = createAction(
  "[DiveLogLine Put Page] Create DiveLogLine"
);

export const updateDiveLogLine = createAction(
  "[DiveLogLine Put Page] Update DiveLogLine"
);
