import { IMany2One } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectFilter,
  selectId,
  selectStatus,
} from "./checklist-templates.reducer";

export const selectChecklistTemplates = (state: AppState) =>
  state.checklistTemplates;

export const {
  selectIds: selectChecklistTemplateIds,
  selectEntities: selectChecklistTemplateEntities,
  selectAll: selectAllChecklistTemplates,
  selectTotal: selectChecklistTemplatesTotal,
} = adapter.getSelectors(selectChecklistTemplates);

export const selectChecklistTemplatesStatus = createSelector(
  selectChecklistTemplates,
  selectStatus
);

export const selectChecklistTemplateId = createSelector(
  selectChecklistTemplates,
  selectId
);

export const selectChecklistTemplate = createSelector(
  selectChecklistTemplateEntities,
  selectChecklistTemplateId,
  (entities, selectedId) => !!selectedId && entities[selectedId]
);

export const selectChecklistTemplateFilter = createSelector(
  selectChecklistTemplates,
  selectFilter
);

export const selectSelectableChecklistTemplates = createSelector(
  selectAllChecklistTemplates,
  selectChecklistTemplateFilter,
  (templates, filter) =>
    templates
      .filter((template) => (filter ? template.name.includes(filter) : true))
      .map((template) => <IMany2One>{ id: template.id, name: template.name })
);

export const selectChecklistDefaultTemplate = createSelector(
  selectAllChecklistTemplates,
  (templates) => templates.length && templates[0]
);
