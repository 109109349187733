import { ChecklistLine } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectCanActivate,
  selectError,
  selectForm,
  selectId,
  selectLoaded,
  selectLoading,
  selectState,
} from "./checklist-lines.reducer";

export const selectChecklistLines = (state: AppState) => state.checklistLines;

export const {
  selectIds: selectChecklistLineIds,
  selectEntities: selectChecklistLineEntities,
  selectAll: selectAllChecklistLines,
  selectTotal: selectChecklistLinesTotal,
} = adapter.getSelectors(selectChecklistLines);

export const selectChecklistLineError = createSelector(
  selectChecklistLines,
  selectError
);

export const selectChecklistLineLoading = createSelector(
  selectChecklistLines,
  selectLoading
);

export const selectChecklistLinesLoaded = createSelector(
  selectChecklistLines,
  selectLoaded
);

export const selectChecklistLinesCanActivate = createSelector(
  selectChecklistLines,
  selectCanActivate
);

export const selectChecklistLineId = createSelector(
  selectChecklistLines,
  selectId
);

export const _selectChecklistLine = createSelector(
  selectChecklistLineEntities,
  selectChecklistLineId,
  (entities, selectedId) =>
    (!!selectedId && entities[selectedId]) || new ChecklistLine()
);

// TODO checklistLine state with other selector?
export const selectChecklistLine = createSelector(
  _selectChecklistLine,
  (checklistLine) =>
    checklistLine && { ...checklistLine, state: selectState(checklistLine) }
);

export const selectChecklistLineForm = createSelector(
  selectChecklistLines,
  selectForm
);
