import { EnqueuedGroup } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { OfflineQueueApiActions } from "../actions";
import { StateStatus } from "../model";

export interface OfflineQueueState extends EntityState<EnqueuedGroup> {
  selectedId: string | null;
  error: string;
  filter: any[];
  status: StateStatus;
}

export const adapter: EntityAdapter<EnqueuedGroup> =
  createEntityAdapter<EnqueuedGroup>({
    selectId: (syncGroup: EnqueuedGroup) => syncGroup._id,
    sortComparer: false,
  });

export const offlineQueueInitialState: OfflineQueueState =
  adapter.getInitialState({
    selectedId: null,
    error: null,
    filter: [],
    status: StateStatus.Pending,
  });

export const offlineQueueReducer = createReducer(
  offlineQueueInitialState,

  on(OfflineQueueApiActions.searchOfflineQueue, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    OfflineQueueApiActions.searchOfflineQueueSuccess,
    (state, { enqueuedGroups: syncGroups }) =>
      adapter.setAll(syncGroups, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(OfflineQueueApiActions.searchOfflineQueueFailure, (state, { error }) => ({
    ...state,
    status: StateStatus.Error,
    error: error,
  })),

  on(OfflineQueueApiActions.enqueueRequestSuccess, (state, { enqueuedGroup }) =>
    adapter.upsertOne({ ...enqueuedGroup, status: "pending" }, state)
  ),

  on(OfflineQueueApiActions.processEnqueuedGroup, (state, { enqueuedGroup }) =>
    adapter.upsertOne({ ...enqueuedGroup, status: "syncing" }, state)
  ),

  on(
    OfflineQueueApiActions.processEnqueuedGroupSuccess,
    (state, { enqueuedGroup: syncGroup }) =>
      adapter.removeOne(syncGroup._id, state)
  ),

  on(
    OfflineQueueApiActions.processEnqueuedGroupFailure,
    (state, { enqueuedGroup }) =>
      adapter.upsertOne({ ...enqueuedGroup, status: "error" }, state)
  )
);

export const selectId = (state: OfflineQueueState) => state.selectedId;

export const selectStatus = (state: OfflineQueueState) => state.status;
