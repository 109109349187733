import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { adapter, selectId } from "./dive-jobs.reducer";

export const selectDiveJobs = (state: AppState) => state.diveJobs;

export const {
  selectIds: selectDiveJobIds,
  selectEntities: selectDiveJobEntities,
  selectAll: selectAllDiveJobs,
  selectTotal: selectDiveJobsTotal,
} = adapter.getSelectors(selectDiveJobs);

export const selectDiveJobId = createSelector(selectDiveJobs, selectId);

export const selectDiveJob = createSelector(
  selectDiveJobEntities,
  selectDiveJobId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
