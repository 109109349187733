import {
  ApiId,
  Attachment,
  AttachmentStatus,
  IAttachmentForm,
} from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  createFormGroupState,
  FormGroupState,
  markAsDirty,
  markAsPristine,
  markAsTouched,
  onNgrxForms,
  reset,
  setUserDefinedProperty,
  setValue,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { getSelectId, StateStatus } from "../model";
import {
  AttachmentApiActions,
  AttachmentListPageActions,
  AttachmentPutPageActions,
  AttachmentViewPageActions,
} from "./actions";

export const FORM_ID = "ir.attachment";

export interface AttachmentsState extends EntityState<Attachment> {
  selectedId: ApiId;
  error: string;
  status: StateStatus;
  form: FormGroupState<IAttachmentForm>;
}

export const initialFormValue: IAttachmentForm = {
  _id: null,
  name: null,
  mimetype: null,
  description: null,
};

export const adapter: EntityAdapter<Attachment> =
  createEntityAdapter<Attachment>({
    selectId: (attachment: Attachment) => attachment._id,
    sortComparer: false,
  });

export const validateFormGroup = updateGroup<IAttachmentForm>({
  _id: validate(required),
  name: validate(required),
  mimetype: validate(required),
});

export const initialState: AttachmentsState = adapter.getInitialState({
  selectedId: null,
  error: null,
  status: StateStatus.Pending,
  form: createFormGroupState<IAttachmentForm>(FORM_ID, initialFormValue),
});

export const rawReducer = createReducer(
  initialState,

  onNgrxForms(),

  on(
    AttachmentViewPageActions.setAttachment,
    AttachmentPutPageActions.setAttachment,
    (state, { attachmentId }) => ({
      ...state,
      selectedId: getSelectId(state, attachmentId),
    })
  ),

  on(AttachmentViewPageActions.unsetAttachment, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(AttachmentListPageActions.searchAttachments, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(AttachmentApiActions.searchAttachmentsSuccess, (state, { attachments }) =>
    adapter.setAll(
      attachments.map((attachment) => ({
        ...attachment,
        status: AttachmentStatus.Loading,
      })),
      {
        ...state,
        status: StateStatus.Success,
        error: null,
      }
    )
  ),

  on(AttachmentApiActions.searchAttachmentsFailure, (state, { error }) => ({
    ...state,
    status: StateStatus.Error,
    error: error,
  })),

  on(
    AttachmentPutPageActions.createAttachment,
    AttachmentPutPageActions.updateAttachment,
    AttachmentViewPageActions.deleteAttachment,
    AttachmentPutPageActions.setAttachmentFile,
    (state) => ({
      ...state,
      status: StateStatus.Loading,
      error: null,
    })
  ),

  on(
    AttachmentApiActions.createAttachmentSuccess,
    AttachmentApiActions.updateAttachmentSuccess,
    (state, { attachment }) =>
      adapter.upsertOne(
        { ...attachment, status: AttachmentStatus.Success },
        {
          ...state,
          status: StateStatus.Success,
          error: null,
          form: markAsPristine(state.form),
        }
      )
  ),

  on(AttachmentApiActions.syncAttachmentSuccess, (state, { attachment }) =>
    adapter.upsertOne(attachment, state)
  ),

  on(AttachmentApiActions.deleteAttachmentSuccess, (state, { attachmentId }) =>
    adapter.removeOne(attachmentId.toString(), {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(AttachmentApiActions.ensureAttachmentSuccess, (state, { attachment }) =>
    adapter.upsertOne(
      { ...attachment, status: AttachmentStatus.Success },
      state
    )
  ),

  on(
    AttachmentApiActions.ensureAttachmentFailure,
    (state, { attachment, error }) =>
      adapter.upsertOne(
        { ...attachment, status: AttachmentStatus.Error, error: error },
        state
      )
  ),

  on(
    AttachmentApiActions.searchAttachmentsFailure,
    AttachmentApiActions.createAttachmentFailure,
    AttachmentApiActions.updateAttachmentFailure,
    AttachmentApiActions.deleteAttachmentFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(
    AttachmentApiActions.setAttachmentForm,
    (state, { attachmentFormValue, thumbnail }) => ({
      ...state,
      form: setValue(
        setUserDefinedProperty(state.form, "thumbnail", thumbnail),
        {
          ...state.form.value,
          ...attachmentFormValue,
        }
      ),
    })
  ),

  on(
    AttachmentPutPageActions.setAttachmentFileSuccess,
    (state, { name, mimetype, thumbnail }) => ({
      ...state,
      status: StateStatus.Success,
      error: null,
      form: updateGroup(
        setUserDefinedProperty(state.form, "thumbnail", thumbnail),
        [
          {
            name: setValue(name),
            mimetype: setValue(mimetype),
          },
          {
            name: markAsDirty,
            mimetype: markAsDirty,
          },
        ]
      ),
    })
  ),

  on(AttachmentApiActions.attachmentFormInvalid, (state) => ({
    ...state,
    status: StateStatus.Success,
    form: markAsTouched(state.form),
  })),

  on(AttachmentPutPageActions.resetForm, (state) => ({
    ...state,
    form: setValue(reset(state.form), initialFormValue),
  }))
);

export const attachmentsReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateFormGroup
);

export const selectId = (state: AttachmentsState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectForm = (state: AttachmentsState) => state.form;

export const selectError = (state: AttachmentsState) => state.error;

export const selectLoading = (state: AttachmentsState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: AttachmentsState) =>
  state.status == StateStatus.Success;

export const selectCanActivate = (state: AttachmentsState) =>
  ![StateStatus.Pending, StateStatus.Loading].includes(state.status);
