import { ApiId } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setDiveLogLine = createAction(
  "[DiveLogLine View Page] Set DiveLogLine",
  props<{ logLineId: ApiId }>()
);

export const unsetDiveLogLine = createAction(
  "[DiveLogLine View Page] Unset DiveLogLine"
);

export const updateDiveLogLine = createAction(
  "[DiveLogLine View Page] Open DiveLogLine Edit Page"
);
