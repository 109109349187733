import { getApiId } from "@app/api/utils";
import { ApiId, Checklist } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  ChecklistLineApiActions,
  ChecklistLinePutPageActions,
} from "../actions";
import { getSelectId, StateStatus } from "../model";
import { ChecklistApiActions, ChecklistViewPageActions } from "./actions";

export interface ChecklistState extends EntityState<Checklist> {
  selectedId: ApiId;
  error: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<Checklist> = createEntityAdapter<Checklist>(
  {
    selectId: (checklist: Checklist) => checklist._id,
    sortComparer: false,
  }
);

export const initialState: ChecklistState = adapter.getInitialState({
  selectedId: null,
  error: null,
  status: StateStatus.Pending,
});

export const checklistReducer = createReducer(
  initialState,

  on(ChecklistApiActions.searchChecklists, (state) => ({
    ...state,
    status: StateStatus.Loading,
  })),

  on(ChecklistApiActions.searchChecklistsSuccess, (state, { checklists }) =>
    adapter.setAll(checklists, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(
    ChecklistViewPageActions.setChecklist,
    ChecklistLinePutPageActions.setChecklist,
    ChecklistLineApiActions.setChecklist,
    (state, { checklistId }) => ({
      ...state,
      selectedId: getSelectId(state, checklistId),
    })
  ),

  on(
    ChecklistApiActions.viewChecklist,
    ChecklistApiActions.createChecklistSuccess,
    (state, { checklist }) => ({
      ...state,
      selectedId: getApiId(checklist),
    })
  ),

  on(
    ChecklistApiActions.createChecklistSuccess,
    ChecklistApiActions.syncChecklistSuccess,
    (state, { checklist }) =>
      adapter.upsertOne(checklist, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    ChecklistApiActions.searchChecklistsFailure,
    ChecklistApiActions.createChecklistFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  )
);

export const selectId = (state: ChecklistState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectStatus = (state: ChecklistState) => state.status;

export const selectError = (state: ChecklistState) => state.error;

export const selectLoading = (state: ChecklistState) =>
  state.status == StateStatus.Loading;
