import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  selectError,
  selectForm,
  selectLoaded,
  selectLoading,
} from "./auth.reducer";

export const selectAuth = (state: AppState) => state.auth;

export const selectAuthError = createSelector(selectAuth, selectError);

export const selectAuthLoading = createSelector(selectAuth, selectLoading);

export const selectAuthLoaded = createSelector(selectAuth, selectLoaded);

export const selectAuthForm = createSelector(selectAuth, selectForm);

export const selectIsLoggedIn = createSelector(
  selectAuth,
  (state) => state.loggedIn
);

export const selectLoggedUser = createSelector(
  selectAuth,
  (state) => state.user
);

export const selectLoggedUserName = createSelector(
  selectLoggedUser,
  (state) => state.name
);

export const selectLoggedUserCompanies = createSelector(
  selectLoggedUser,
  (state) => state.company_ids
);

export const selectLoggedUserIsPro = createSelector(selectLoggedUser, (state) =>
  ["dive_supervisor_pro", "operation_manager_pro"].includes(state.role)
);
