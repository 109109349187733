import { ApiId, ResPartner } from "@app/models";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { StateStatus } from "../model";
import { PartnerApiActions } from "./actions";

export interface PartnerState extends EntityState<ResPartner> {
  selectedId: ApiId;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<ResPartner> =
  createEntityAdapter<ResPartner>({
    selectId: (diveJob: ResPartner) => diveJob.id,
    sortComparer: false,
  });

export const initialState: PartnerState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const partnerReducer = createReducer(
  initialState,

  on(PartnerApiActions.searchPartners, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(PartnerApiActions.searchPartnersSuccess, (state, { partners }) =>
    adapter.setAll(partners, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(PartnerApiActions.searchPartnersFailure, (state, { error }) => ({
    ...state,
    status: StateStatus.Error,
    error: error,
  }))
);

export const selectId = (state: PartnerState) => state.selectedId;
