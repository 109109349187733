import { AppState } from "../app.state";
import { createSelector } from "@ngrx/store";
import { adapter, selectFilter, selectId } from "./users.reducer";

export const selectUsers = (state: AppState) => state.users;

export const {
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectUsersTotal,
} = adapter.getSelectors(selectUsers);

export const selectUserId = createSelector(selectUsers, selectId);

export const selectUser = createSelector(
  selectUserEntities,
  selectUserId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
