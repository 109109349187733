import { ApiId, DiveOperation, IDiveOperationForm } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const setOperation = createAction(
  "[Operation API] Set Operation",
  props<{ operationId: ApiId }>()
);

export const createOperationSuccess = createAction(
  "[Operation API] Operation Create Success",
  props<{ operation: DiveOperation }>()
);

export const createOperationFailure = createAction(
  "[Operation API] Operation Create Failure",
  props<{ error: string }>()
);

export const updateOperationSuccess = createAction(
  "[Operation API] Operation Update Success",
  props<{ operation: DiveOperation }>()
);

export const updateOperationFailure = createAction(
  "[Operation API] Operation Update Failure",
  props<{ error: string }>()
);

export const deleteOperationSuccess = createAction(
  "[Operation API] Operation Delete Success",
  props<{ id: string }>()
);

export const deleteOperationFailure = createAction(
  "[Operation API] Operation Delete Failure",
  props<{ error: string }>()
);

export const searchOperationsSuccess = createAction(
  "[Operation API] Operation Search Success",
  props<{ operations: DiveOperation[] }>()
);

export const searchOperationsFailure = createAction(
  "[Operation API] Operation Search Failure",
  props<{ error: string }>()
);

export const synchronizeOperationSuccess = createAction(
  "[Operation API] Synchronize Operation Success",
  props<{ operation: any }>()
);

export const synchronizeOperationGroupPending = createAction(
  "[Operation API] Synchronize Operation Group Pending",
  props<{ operation: any }>()
);

export const synchronizeOperationGroupStarted = createAction(
  "[Operation API] Synchronize Operation Group Started",
  props<{ operation: any }>()
);

export const synchronizeOperationGroupSuccess = createAction(
  "[Operation API] Synchronize Operation Group Success",
  props<{ operation: any }>()
);

export const synchronizeOperationGroupFailure = createAction(
  "[Operation API] Synchronize Operation Group Failure",
  props<{ operation: any }>()
);

export const setOperationForm = createAction(
  "[Operation API] Set Operation Controls",
  props<{ operationFormValue: IDiveOperationForm }>()
);

export const operationFormInvalid = createAction(
  "[Operation API] Operation Form Invalid"
);

export const updateOperationTimesheetsSuccess = createAction(
  "[Operation API] Operation Timesheets Update Success",
  props<{ operation: DiveOperation }>()
);

export const updateOperationTimesheetsFailure = createAction(
  "[Operation API] Operation Timesheets Update Failure",
  props<{ error: string }>()
);
