import { ApiId, DiveLogTemplate } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { DiveLogLinePutPageActions } from "../actions";
import { StateStatus } from "../model";
import { DiveLogTemplateApiActions } from "./actions";

export interface DiveLogTemplateState extends EntityState<DiveLogTemplate> {
  selectedId: ApiId;
  error: string;
  filter: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<DiveLogTemplate> =
  createEntityAdapter<DiveLogTemplate>({
    selectId: (diveLogTemplate: DiveLogTemplate) => diveLogTemplate.id,
    sortComparer: false,
  });

export const initialState: DiveLogTemplateState = adapter.getInitialState({
  selectedId: null,
  error: null,
  filter: null,
  status: StateStatus.Pending,
});

export const diveLogTemplateReducer = createReducer(
  initialState,

  on(DiveLogTemplateApiActions.searchDiveLogTemplates, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(
    DiveLogTemplateApiActions.searchDiveLogTemplatesSuccess,
    (state, { diveLogTemplates }) =>
      adapter.setAll(diveLogTemplates, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  ),

  on(
    DiveLogTemplateApiActions.searchDiveLogTemplatesFailure,
    (state, { error }) => ({
      ...state,
      status: StateStatus.Error,
      error: error,
    })
  ),

  on(DiveLogLinePutPageActions.setTemplate, (state, { id }) => ({
    ...state,
    selectedId: id,
  }))
);

export const selectId = (state: DiveLogTemplateState) => state.selectedId;

export const selectFilter = (state: DiveLogTemplateState) => state.filter;

export const selectStatus = (state: DiveLogTemplateState) => state.status;
