import { ToolboxTalkTemplate } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchToolboxTalkTemplates = createAction(
  "[ToolboxTalkTemplate API] Search ToolboxTalkTemplates"
);

export const searchToolboxTalkTemplatesSuccess = createAction(
  "[ToolboxTalkTemplate API] ToolboxTalkTemplates Search Success",
  props<{ toolboxTalkTemplates: ToolboxTalkTemplate[] }>()
);

export const searchToolboxTalkTemplatesFailure = createAction(
  "[ToolboxTalkTemplate API] ToolboxTalkTemplates Search Failure",
  props<{ error: string }>()
);
