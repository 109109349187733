import { Company, ICompanyForm } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  createFormGroupState,
  FormGroupState,
  onNgrxForms,
  setValue,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { CompanyApiActions } from "../actions";

export const FORM_ID = "res.company";

export interface CompanyState extends EntityState<Company> {
  selectedId: number;
  form: FormGroupState<ICompanyForm>;
}

export const initialFormGroupValue: ICompanyForm = {
  company_id: null,
};

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: (company: Company) => company.id,
  sortComparer: false,
});

export const initialState: CompanyState = adapter.getInitialState({
  selectedId: null,
  form: createFormGroupState<ICompanyForm>(FORM_ID, initialFormGroupValue),
});

const validateFormState = updateGroup<ICompanyForm>({
  company_id: validate(required),
});

export const rawReducer = createReducer(
  initialState,
  onNgrxForms(),

  on(CompanyApiActions.setCompanies, (state, { companies, selectedId }) =>
    adapter.setAll(companies, { ...state, selectedId: selectedId })
  ),

  on(CompanyApiActions.setFormControls, (state, { companyForm }) => ({
    ...state,
    form: setValue(state.form, companyForm),
  }))
);

export const companyReducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  (s) => s.form,
  validateFormState
);

export const selectId = (state: CompanyState) => state.selectedId;

export const selectForm = (state: CompanyState) => state.form;
