import { ApiId, DiveLog } from "@app/models";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { DiveLogLineApiActions, DiveLogLinePutPageActions } from "../actions";
import { getApiId, getSelectId, StateStatus } from "../model";
import { DiveLogApiActions, DiveLogViewPageActions } from "./actions";

export interface DiveLogState extends EntityState<DiveLog> {
  selectedId: ApiId;
  error: string;
  status: StateStatus;
}

export const adapter: EntityAdapter<DiveLog> = createEntityAdapter<DiveLog>({
  selectId: (DiveLog: DiveLog) => DiveLog._id,
  sortComparer: false,
});

export const initialState: DiveLogState = adapter.getInitialState({
  selectedId: null,
  error: null,
  status: StateStatus.Pending,
});

export const diveLogReducer = createReducer(
  initialState,

  on(DiveLogApiActions.searchDiveLogs, (state) => ({
    ...state,
    status: StateStatus.Loading,
    error: null,
  })),

  on(DiveLogApiActions.searchDiveLogsSuccess, (state, { diveLogs }) =>
    adapter.setAll(diveLogs, {
      ...state,
      status: StateStatus.Success,
      error: null,
    })
  ),

  on(
    DiveLogViewPageActions.setDiveLog,
    DiveLogLinePutPageActions.setDiveLog,
    DiveLogLineApiActions.setDiveLog,
    (state, { logId }) => ({
      ...state,
      selectedId: getSelectId(state, logId),
    })
  ),

  on(DiveLogViewPageActions.unsetDiveLog, (state) => ({
    ...state,
    selectedId: null,
  })),

  on(
    DiveLogApiActions.viewDiveLog,
    DiveLogApiActions.createDiveLogSuccess,
    (state, { diveLog }) => ({
      ...state,
      selectedId: getApiId(diveLog),
    })
  ),

  on(
    DiveLogApiActions.createDiveLogSuccess,
    DiveLogApiActions.syncDiveLogSuccess,
    (state, { diveLog }) =>
      adapter.upsertOne(diveLog, {
        ...state,
        status: StateStatus.Success,
        error: null,
      })
  )
);

export const selectId = (state: DiveLogState) =>
  (!Number.isNaN(state.selectedId) && Number(state.selectedId)) ||
  state.selectedId;

export const selectStatus = (state: DiveLogState) => state.status;

export const selectError = (state: DiveLogState) => state.error;

export const selectLoading = (state: DiveLogState) =>
  state.status == StateStatus.Loading;

export const selectLoaded = (state: DiveLogState) =>
  state.status == StateStatus.Success;
